import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../baseUrl";

const initialState = {
  spa: {},
  loading: true,
  error: null,
};

export const fetchSpa = createAsyncThunk("spa/fetchSpa", async (hotelUrl) => {
  const response = await axios.get(`${baseUrl}/api/SPA/en/${hotelUrl}`);
  return response.data;
});

const spaSlice = createSlice({
  name: "spa",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchSpa.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchSpa.fulfilled, (state, action) => {
        state.loading = false;
        state.spa = action.payload;
      })
      .addCase(fetchSpa.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default spaSlice.reducer;
