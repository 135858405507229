import {
  faExpand,
  faUpRightAndDownLeftFromCenter,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMediaQuery } from "@mui/material";
import React, { useEffect } from "react";
import { Fade, Slide } from "react-awesome-reveal";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { fetchMeetings } from "../../data/meetings/meetingsSlice";
import { Helmet } from "react-helmet";
import Loader from "../../Loader";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import HomeIcon from "@mui/icons-material/Home";
const MeetingAndEvents = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const hotelUrl = localStorage.getItem("hotelUrl");
  const isMobile = useMediaQuery("(max-width:767px)");
  const isTablet = useMediaQuery("(max-width:1024px)");

  const { meetings, loading, error } = useSelector((state) => state.meetings);
  useEffect(() => {
    dispatch(fetchMeetings(hotelUrl));
  }, [dispatch, hotelUrl]);
  return (
    <>
      {loading ? (
        <Loader />
      ) : error ? (
        <section
          style={{ backgroundColor: "#293839", padding: "200px 0 100px" }}
        >
          <h1 className="text-center text-light">{error}</h1>
        </section>
      ) : (
        <>
          <Helmet>
            <title>{meetings.pageDetails.pageMetatagTitle}</title>

            <meta
              name="description"
              content={meetings.pageDetails.pageMetatagDescription}
            />
          </Helmet>
          <section className="hero -type-10">
            <div
              className="hero__slider js-section-slider"
              data-gap="0"
              data-slider-cols="xl-1 lg-1 md-1 sm-1 base-1"
              data-nav-prev="js-sliderHero-prev"
              data-nav-next="js-sliderHero-next"
              data-pagination="js-sliderHero-pagination"
            >
              <div className="swiper-wrapper">
                <div className="swiper-slide">
                  <div className="hero__slide">
                    <div className="hero__bg">
                      <div className="img-ratio">
                        <img
                          src={
                            isMobile
                              ? meetings.pageDetails.pageBannerMobile
                              : isTablet
                              ? meetings.pageDetails.pageBannerTablet
                              : meetings.pageDetails.pageBannerPC
                          }
                          alt={meetings?.pageDetails.pageTitle}
                          className="img-ratio"
                        />
                      </div>
                    </div>

                    <div className="hero__content text-center">
                      <div>
                        <Fade direction="up">
                          {" "}
                          <h1 className="hero__title capitalize text-white">
                            {meetings?.pageDetails.pageTitle}
                          </h1>
                        </Fade>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div
            role="presentation"
            className="breadcrumbs-wrapper py-3 bg-light-1"
          >
            <div className="container-fluid">
              <Breadcrumbs aria-label="breadcrumb" className="ps-lg-4">
                <Link
                  className="d-flex align-items-center"
                  underline="hover"
                  sx={{ display: "flex", alignItems: "center" }}
                  color="inherit"
                  to={`/`}
                >
                  <HomeIcon
                    sx={{ mr: 0.5, fontSize: "18px" }}
                    fontSize="inherit"
                  />
                  Home
                </Link>
                <Link
                  underline="hover"
                  sx={{ display: "flex", alignItems: "center" }}
                  color="text.primary"
                  to={`/${hotelUrl}`}
                >
                  {hotelUrl === "aladdin-beach-resort"
                    ? "Aladdin Beach Resort "
                    : hotelUrl === "ali-baba-palace-hotel" ||
                      hotelUrl === "ali-baba-palace"
                    ? "Ali Baba Palace"
                    : "Jasmine Village"}
                </Link>

                <Typography
                  sx={{ display: "flex", alignItems: "center" }}
                  color="text.primary"
                >
                  {meetings?.pageDetails.pageTitle}
                </Typography>
              </Breadcrumbs>
            </div>
          </div>
          <section className="layout-pt-lg layout-pb-lg">
            <div className="container">
              <div className="row justify-center text-center">
                <div className="col-xl-8 col-lg-10">
                  <Fade cascade direction="up">
                    <h2 className="text-40 md:text-30">
                      {meetings?.pageDetails.pageTitle}
                    </h2>
                    <p className="mt-40">{meetings?.pageDetails.pageText}</p>
                  </Fade>

                  <div className="row y-gap-40 x-gap-60 md:x-gap-20 justify-center pt-60 md:pt-40">
                    <Fade direction="left" className="col-6">
                      <div className="text-center">
                        <i className="d-flex justify-center icon-phone text-40 mb-20"></i>
                        <Link
                          to={`tel:${
                            meetings.mobile ? meetings.mobile : "+2010123456789"
                          }`}
                          className="fw-500"
                        >
                          {meetings.mobile ? meetings.mobile : "+2010123456789"}
                        </Link>
                      </div>
                    </Fade>
                    <Fade direction="right" className="col-6">
                      <div className="text-center">
                        <i className="d-flex justify-center icon-email-2 text-40 mb-20"></i>
                        <Link
                          to={`mailto:${
                            meetings.email
                              ? meetings.email
                              : "mice@hwaidakhotels.com"
                          }`}
                          className="fw-500"
                        >
                          {meetings.email
                            ? meetings.email
                            : "mice@hwaidakhotels.com"}
                        </Link>
                      </div>
                    </Fade>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {meetings.meetingEvent.map((meeting, index) =>
            index % 2 === 0 ? (
              <section
                className="  layout-pb-lg "
                key={meeting.facilityUrl}
                id={meeting.facilityUrl}
              >
                <div className="container">
                  <div className="row y-gap-30 items-center justify-between">
                    <div className="col-lg-6">
                      <div
                        className="relative overflow-hidden js-section-slider"
                        data-gap="0"
                        data-slider-cols="xl-1 lg-1 md-1 sm-1 base-1"
                        data-loop=""
                        data-pagination="js-slider1-pagination"
                      >
                        <div className="swiper-wrapper">
                          <div className="swiper-slide">
                            <div className="ratio ratio-1:1">
                              <Slide className="img-ratio">
                                <img
                                  src={meeting.facilityPhoto}
                                  alt={meeting.facilityName}
                                  className="img-ratio"
                                />
                              </Slide>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-xl-5 col-lg-6">
                      <i className="d-block icon-meeting text-30 mb-30"></i>
                      <h3 className="text-40 md:text-30 lh-065">
                        {meeting.facilityName}
                      </h3>
                      <div className="d-flex flex-wrap x-gap-20 pt-40 md:pt-30">
                        {meeting.meetingSize && (
                          <div className="d-flex items-center text-accent-1">
                            Size: {meeting.meetingSize}
                          </div>
                        )}

                        {meeting.meetingWidths && (
                          <>
                            |
                            <div className="d-flex items-center text-accent-1">
                              Widths: {meeting.meetingWidths}
                            </div>
                          </>
                        )}
                        {meeting.meetingLength && (
                          <>
                            |
                            <div className="d-flex items-center text-accent-1">
                              Length: {meeting.meetingLength}
                            </div>
                          </>
                        )}
                        {meeting.meetingCellingHeight && (
                          <>
                            |
                            <div className="d-flex items-center text-accent-1">
                              Celling Height: {meeting.meetingCellingHeight}
                            </div>
                          </>
                        )}
                        {meeting.meetingCapacity && (
                          <>
                            |
                            <div className="d-flex items-center text-accent-1">
                              Capacity: {meeting.meetingCapacity}
                            </div>
                          </>
                        )}
                      </div>
                      <hr style={{ opacity: "0.1" }} />
                      <p className="mt-30">{meeting.facilitySummery}</p>
                      {meeting.isMoreDetails && (
                        <button
                          className="button -md -outline-accent-1 -type-2 mt-50 md:mt-40"
                          onClick={() =>
                            navigate(
                              `/${meeting.hotelUrl}/meetingandevents/${meeting.facilityUrl}`
                            )
                          }
                        >
                          View
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </section>
            ) : (
              <section
                className="   layout-pb-lg  "
                key={meeting.facilityUrl}
                id={meeting.facilityUrl}
              >
                <div className="container">
                  <div className="row d-flex flex-column-reverse flex-lg-row  y-gap-30 items-center justify-between">
                    <div className="col-xl-5 col-lg-6">
                      <i className="d-block icon-meeting text-30 mb-30"></i>
                      <h3 className="text-40 md:text-30 lh-065">
                        {meeting.facilityName}
                      </h3>
                      <div className="d-flex flex-wrap x-gap-20 pt-40 md:pt-30">
                        {meeting.meetingSize && (
                          <div className="d-flex items-center text-accent-1">
                            Size: {meeting.meetingSize}
                          </div>
                        )}

                        {meeting.meetingWidths && (
                          <>
                            |
                            <div className="d-flex items-center text-accent-1">
                              Widths: {meeting.meetingWidths}
                            </div>
                          </>
                        )}
                        {meeting.meetingLength && (
                          <>
                            |
                            <div className="d-flex items-center text-accent-1">
                              Length: {meeting.meetingLength}
                            </div>
                          </>
                        )}
                        {meeting.meetingCellingHeight && (
                          <>
                            |
                            <div className="d-flex items-center text-accent-1">
                              Celling Height: {meeting.meetingCellingHeight}
                            </div>
                          </>
                        )}
                        {meeting.meetingCapacity && (
                          <>
                            |
                            <div className="d-flex items-center text-accent-1">
                              Capacity: {meeting.meetingCapacity}
                            </div>
                          </>
                        )}
                      </div>
                      <hr style={{ opacity: "0.1" }} />
                      <p className="mt-20">{meeting.facilitySummery}</p>
                      {meeting.isMoreDetails && (
                        <button
                          className="button -md -outline-accent-1 -type-2 mt-50 md:mt-40"
                          onClick={() =>
                            navigate(
                              `/${meeting.hotelUrl}/meetingandevents/${meeting.facilityUrl}`
                            )
                          }
                        >
                          View
                        </button>
                      )}
                    </div>{" "}
                    <div className="col-lg-6">
                      <div
                        className="relative overflow-hidden js-section-slider"
                        data-gap="0"
                        data-slider-cols="xl-1 lg-1 md-1 sm-1 base-1"
                        data-loop=""
                        data-pagination="js-slider1-pagination"
                      >
                        <div className="swiper-wrapper">
                          <div className="swiper-slide">
                            <div className="ratio ratio-1:1">
                              <Slide className="img-ratio">
                                <img
                                  src={meeting.facilityPhoto}
                                  alt={meeting.facilityName}
                                  className="img-ratio"
                                />
                              </Slide>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            )
          )}
        </>
      )}
    </>
  );
};

export default MeetingAndEvents;
