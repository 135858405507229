import {
  faChevronDown,
  faChevronUp,
  faEnvelope,
  faGlobeAsia,
  faGlobeEurope,
  faLocation,
  faLocationDot,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Bounce, Slide, Fade, Rotate } from "react-awesome-reveal";
import { Link, Outlet, useLocation } from "react-router-dom";
import Loader from "./../Loader";

import { useDispatch, useSelector } from "react-redux";

import { fetchLayoutGroup } from "../data/layoutGroupSlice.js";
import axios from "axios";
import { Alert, Snackbar } from "@mui/material";
const LayoutGroup = () => {
  const [openLangMen, setOpenLangMenu] = useState(false);
  const [viewHotels, setViewHotels] = useState(false);
  const [viewDining, setViewDining] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);
  const toggleHotels = () => {
    setViewHotels(!viewHotels);
  };
  const toggleDining = () => {
    setViewDining(!viewDining);
  };
  const { layoutGroup, loading, error } = useSelector(
    (state) => state.layoutGroup
  );
  const { hotels } = useSelector((state) => state.hotels);
  // This could be expanded to manage the actual language selection in your app
  const [language, setLanguage] = useState("EN");
  const [flag, setFlag] = useState("");
  const [addClass, setAddClass] = useState(false);

  const handleScroll = () => {
    const offset = window.scrollY;
    const threshold = 100; // Change this value to whatever suits your needs
    if (offset > threshold) {
      setAddClass(true);
    } else {
      setAddClass(false);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const id = location.hash.replace("#", "");
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);
  const [visible, setVisible] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      if (currentScrollY > lastScrollY) {
        setVisible(false); // Hide header on scroll down
      } else {
        setVisible(true); // Show header on scroll up
      }
      setLastScrollY(currentScrollY);
    };

    // Add event listener
    window.addEventListener("scroll", handleScroll);

    // Clean up
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollY]);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchLayoutGroup());
  }, [dispatch]);
  const [email, setEmail] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    setEmail(e.target.value);
  };
  const [errorMessage, setErrorMessage] = useState("");
  const [errorOperation, setErrorOperation] = useState(false);
  const [successOperation, setSuccessOperation] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      await axios.post(
        `https://api.hwaidakhotels.com/api/ContactUs/Newsletter-Submit?request=${email}`
      );
      setSuccessOperation(true);
      setEmail("");
    } catch (error) {
      setErrorMessage(error.message);
      setSuccessOperation(false);
    } finally {
      setIsSubmitting(false);
    }
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSuccessOperation(false);
    setErrorOperation(false);
  };
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);
  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  useEffect(() => {
    if (location.hash) {
      const id = location.hash.replace("#", "");
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className={`menuFullScreen   ${isMenuOpen ? "is-active" : ""}`}>
            <div className="menuFullScreen__topMobile py-0 ">
              <div className="d-flex justify-content-between col-12 ">
                <div
                  className="d-flex items-center text-white  "
                  onClick={() => setIsMenuOpen(!isMenuOpen)}
                >
                  <i className="icon-menu text-9"></i>
                  <div className="text-15 uppercase ml-30 sm:d-none">Menu </div>
                </div>
                <Link
                  to="/"
                  className=" col-5 py-2"
                  onClick={() => {
                    setIsMenuOpen(false);
                  }}
                >
                  <img
                    src={layoutGroup.groupHeader?.groupLogo}
                    alt="logo"
                    className=" py-2"
                  />
                </Link>
              </div>
            </div>
            <div className="menuFullScreen__mobile__bg  "></div>
            <div className="menuFullScreen__left">
              <div className="menuFullScreen__bg  ">
                <img src="bgmenu.jpg" alt="" />
              </div>
              <button
                className="menuFullScreen__close   "
                onClick={() => setIsMenuOpen(false)}
              >
                <span className="icon">
                  <span></span>
                  <span></span>
                </span>
                CLOSE
              </button>

              <div className="menuFullScreen-links  ">
                <div className="menuFullScreen-links__item">
                  <Link
                    onClick={() => {
                      setIsMenuOpen(false);
                    }}
                    to="/"
                  >
                    Home
                  </Link>
                </div>

                {/*<div className="menuFullScreen-links__item">
                  <Link
                    onClick={() => {
                      setIsMenuOpen(false);
                      
                    }}
                    to="#."
                  >
                    Safety & Sustainability
                  </Link>
                </div> */}

                <div className="menuFullScreen-links__item js-menuFullScreen-has-children">
                  <Link to="#." onClick={toggleHotels}>
                    Hotels & Resorts
                    <i
                      className={`icon-arrow-right ${
                        viewHotels
                          ? "mobile-transform-top"
                          : "mobile-transform-bottom"
                      }`}
                    ></i>
                    <i
                      className={`icon-chevron-right ${
                        viewHotels
                          ? "mobile-transform-top"
                          : "mobile-transform-bottom"
                      }`}
                    ></i>
                  </Link>

                  <div
                    className="menuFullScreen-links-subnav js-menuFullScreen-subnav"
                    style={{ maxHeight: viewHotels && "unset" }}
                  >
                    {hotels.map((hotel, index) => (
                      <div
                        className="menuFullScreen-links-subnav__item"
                        key={index}
                      >
                        <Link
                          onClick={() => {
                            setIsMenuOpen(false);

                            localStorage.setItem("hotelUrl", hotel.hotelUrl);
                          }}
                          to={`/${hotel.hotelUrl}`}
                        >
                          {hotel.hotelName}
                        </Link>
                      </div>
                    ))}
                  </div>
                </div>

                {/*<div className="menuFullScreen-links__item">
                  <Link
                    onClick={() => {
                      setIsMenuOpen(false);
                      
                    }}
                    to="#."
                  >
                    Experiences
                  </Link>
                </div> */}

                {layoutGroup.groupHeader?.isEnabledOffers && (
                  <div className="menuFullScreen-links__item">
                    <Link
                      onClick={() => {
                        setIsMenuOpen(false);
                      }}
                      to="/offers"
                    >
                      Offers
                    </Link>
                  </div>
                )}
                <div className="menuFullScreen-links__item">
                  <Link
                    onClick={() => {
                      setIsMenuOpen(false);
                    }}
                    to="/meetingandevents"
                  >
                    Meetings & Events
                  </Link>
                </div>

                <div className="menuFullScreen-links__item">
                  <Link
                    onClick={() => {
                      setIsMenuOpen(false);
                    }}
                    to="/online-check-in"
                  >
                    Online Check In
                  </Link>
                </div>
                <div className="menuFullScreen-links__item">
                  <Link
                    onClick={() => {
                      setIsMenuOpen(false);
                    }}
                    to="contact-us"
                  >
                    Contact Us
                  </Link>
                </div>
              </div>
            </div>
            <div className="menuFullScreen__right  ">
              <div className="text-center">
                <div className="mb-100">
                  <img src="HwaidakHotelsGroupLogo-White.png" alt="logo" />
                </div>

                <div className="text-sec lh-11 fw-500 text-40">Home</div>

                <div className="mt-60">
                  <div className="text-30 text-sec fw-500">Connect With Us</div>
                  <div className="mt-10">
                    <Link to={`tel:${layoutGroup.groupHeader?.groupPhone}`}>
                      {layoutGroup.groupHeader?.groupPhone}
                    </Link>
                  </div>
                  <div className="mt-10">
                    <Link to={`mailto:${layoutGroup.groupFooter?.groupMail}`}>
                      {layoutGroup.groupFooter?.groupMail}
                    </Link>
                  </div>
                </div>
                <div className="mt-20">
                  <div className="text-30 text-sec fw-500">Location</div>
                  <div className="mt-10">
                    {layoutGroup.groupFooter?.groupAddress}
                  </div>
                </div>
              </div>
            </div>
            <hr className="text-light bg-light col-10 mx-auto d-mobile" />
            <div className="menuFullScreen__bottomMobile js-menuFullScreen-buttomMobile text-center">
              <div className="text-30 text-sec fw-500">Connect With Us</div>
              <Link
                to={`tel:${layoutGroup.groupHeader?.groupPhone}`}
                className="d-flex items-center mt-20"
              >
                <i className="icon-phone mr-10"></i>
                <span>{layoutGroup.groupHeader?.groupPhone} </span>
              </Link>
              <Link
                to={`mailto:${layoutGroup.groupFooter?.groupMail}`}
                className="d-flex items-center mt-20"
              >
                <i className="icon-mail mr-10"></i>
                <span>{layoutGroup.groupFooter?.groupMail} </span>
              </Link>
            </div>
          </div>

          <header
            className={`header -h-110 -mx-60 -blur -border-bottom-1 js-header ${
              addClass ? "bg-accent-1" : ""
            } ${visible ? "" : "hidden"}`}
            data-add-bg="bg-accent-1"
            data-x="header"
            data-x-toggle={isMenuOpen ? "-is-menu-opened" : ""}
          >
            <div className="header__container h-full items-center">
              <div className="header__left d-flex items-center">
                <button
                  className="d-flex items-center cursor-pointer"
                  onClick={() => setIsMenuOpen(!isMenuOpen)}
                >
                  <i className="icon-menu text-9 text-white"></i>
                  <div className="text-15 uppercase text-white ml-30 sm:d-none">
                    Menu
                  </div>
                </button>

                <div className="d-flex items-center ml-90 xl:d-none">
                  <i className="icon-phone text-20 text-white mr-30"></i>
                  <div className="text-15 uppercase text-white">
                    <Link
                      className="tel"
                      to={`tel:${layoutGroup.groupHeader?.groupPhone}`}
                    >
                      {layoutGroup.groupHeader?.groupPhone}{" "}
                    </Link>
                  </div>
                </div>
              </div>

              <div className="header__center">
                <Link to="/" className="header__logo">
                  <img src={layoutGroup.groupHeader?.groupLogo} alt="logo" />
                </Link>
              </div>

              <div
                className="header__right d-flex items-center h-full"
                style={{ visibility: "hidden" }}
              >
                <div className="language-selector ">
                  <div
                    className="d-flex"
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => setOpenLangMenu(!openLangMen)}
                  >
                    <button className="button text-white language-button d-flex align-items-center">
                      <FontAwesomeIcon
                        className="mr-5 text-light"
                        style={{ fontSize: "16px" }}
                        icon={faGlobeAsia}
                      />
                      {language}
                    </button>
                    {!openLangMen ? (
                      <FontAwesomeIcon
                        className="ml-5 text-light"
                        style={{ fontSize: "13px", paddingTop: "2px" }}
                        icon={faChevronDown}
                      />
                    ) : (
                      <FontAwesomeIcon
                        className="ml-5 text-light"
                        style={{ fontSize: "13px", paddingTop: "2px" }}
                        icon={faChevronUp}
                      />
                    )}
                  </div>
                  <div
                    className="language-options "
                    style={{
                      display: openLangMen ? "block" : "none",
                      cursor: "pointer",
                      minWidth: "125px",
                    }}
                  >
                    {layoutGroup.groupHeader?.languages?.map((lang, index) => (
                      <div
                        key={index}
                        className="language-option d-flex align-items-center"
                        onClick={() => {
                          setLanguage(`${lang.languageAbbreviation}`);

                          setOpenLangMenu(false);
                        }}
                      >
                        <img
                          alt="flag"
                          className="mr-10"
                          src={lang.languageFlag}
                          width="25"
                        />
                        {lang.languageName}
                      </div>
                    ))}
                  </div>
                </div>

                {/*<div className="line -vertical bg-white-10 h-full ml-90 mr-90 xl:d-none"></div>

                <button className="button text-white mr-30 xl:d-none">
                  Home
                </button> */}
              </div>
            </div>
          </header>
          <main id="main_header">
            <Outlet />
          </main>
          <Link
            to="#"
            onClick={() => scrollToSection("main_header")}
            style={{
              width: "45px",
              height: "45px",
              borderRadius: "50%",
              textAlign: "center",
              padding: "0px",
              display: "block",
              position: "fixed",
              display: scrolled ? "flex" : "none",
              alignItems: "center",
              justifyContent: "center",
              bottom: "20px",
              right: "30px",
              zIndex: 999999,
              padding: "10px",
              backgroundColor: "#fff",
            }}
          >
            <FontAwesomeIcon
              icon={faChevronUp}
              style={{
                fontSize: "20px",
                cursor: "pointer",
                padding: "0px",
                color: "#242c7d",

                borderRadius: "50%",
              }}
            />
          </Link>
          <footer className="footer -type-1 bg-accent-1 text-white">
            <div className="footer__main ">
              <div className="container">
                <div className="row justify-content-between align-items-baseline mx-auto col-lg-10">
                  {/* <div className="col-lg-3">
                    <h4 className="text-30 fw-500 text-white">Home</h4>

                    <div className="text-white-60 text-15 lh-17 mt-50 md:mt-20">
                      {layoutGroup.groupFooter?.groupSummery}
                    </div>
                  </div> */}

                  <div className="col-lg-4 mt-30 mt-lg-0">
                    <h4 className="text-30 fw-500 text-white">Contact </h4>

                    <div className="d-flex flex-column mt-50 md:mt-20">
                      <div>
                        <Link
                          to={`tel:${layoutGroup.groupFooter?.groupPhone}`}
                          className="d-block text-15 text-white-60"
                        >
                          <FontAwesomeIcon icon={faPhone} className="mr-5" />{" "}
                          {layoutGroup.groupFooter?.groupPhone}
                        </Link>
                      </div>{" "}
                      <div className="mt-10">
                        <Link
                          className="d-block text-15 text-white-60"
                          to={`mailto:${layoutGroup.groupFooter?.groupMail}`}
                        >
                          <FontAwesomeIcon icon={faEnvelope} className="mr-5" />{" "}
                          {layoutGroup.groupFooter?.groupMail}
                        </Link>
                      </div>
                      <div className="mt-10">
                        <Link
                          className="d-block text-15 text-white-60 lh-17"
                          to="https://maps.app.goo.gl/WShh4hQpjrQU4Neq8"
                          target="_blank"
                        >
                          <FontAwesomeIcon
                            icon={faLocationDot}
                            className="mr-5"
                          />{" "}
                          {layoutGroup.groupFooter?.groupAddress}
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4  mt-30 mt-lg-0">
                    <h4 className="text-30 fw-500 text-white">Links </h4>

                    <div className="row x-gap-15 y-gap-15 mx-auto">
                      <div className="col-sm-6">
                        <div className="y-gap-10 text-15 text-white-60 mt-40 md:mt-0">
                          {hotels.map((hotel, index) => (
                            <Link
                              key={index}
                              className="d-block"
                              onClick={() =>
                                localStorage.setItem("hotelUrl", hotel.hotelUrl)
                              }
                              to={`/${hotel.hotelUrl}`}
                            >
                              {hotel.hotelName}
                            </Link>
                          ))}

                          <Link className="d-block" to="/meetingandevents">
                            Meeting & Events
                          </Link>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="y-gap-10 text-15 text-white-60 mt-40 md:mt-0">
                          <Link className="d-block" to="/online-check-in">
                            Online Check In
                          </Link>{" "}
                          <Link className="d-block" to="/privacy-policy">
                            Privacy Policy
                          </Link>
                          <Link className="d-block" to="/terms-conditions">
                            Terms & Conditions
                          </Link>
                          {layoutGroup.groupHeader?.isEnabledFaq && (
                            <Link className="d-block" to="/faq">
                              FAQ
                            </Link>
                          )}
                          {layoutGroup.groupHeader?.isEnabledOffers && (
                            <Link className="d-block" to="/offers">
                              Offers
                            </Link>
                          )}
                          <Link className="d-block" to="/contact-us">
                            Contact Us
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4  mt-30 mt-lg-0">
                    <h4 className="text-30 fw-500 text-white">
                      Newsletter Sign Up{" "}
                    </h4>

                    <p className="text-15 text-white-60 mt-40 md:mt-20">
                      Sign up for our news, deals and special offers.
                    </p>

                    <form
                      className="footer__newsletter mt-30"
                      onSubmit={handleSubmit}
                    >
                      <input
                        type="email"
                        placeholder="Your email address"
                        value={email}
                        onChange={handleChange}
                        required
                      />
                      {isSubmitting ? (
                        "Loading..."
                      ) : (
                        <button type="submit" disabled={isSubmitting}>
                          <i className="icon-arrow-right text-white text-20"></i>
                        </button>
                      )}
                    </form>
                    {errorOperation ? (
                      <>
                        <Snackbar
                          open={errorOperation}
                          autoHideDuration={6000}
                          onClose={handleClose}
                        >
                          <Alert
                            onClose={handleClose}
                            severity="error"
                            variant="filled"
                            sx={{ width: "100%" }}
                          >
                            {errorMessage}
                          </Alert>
                        </Snackbar>
                      </>
                    ) : (
                      <Snackbar
                        open={successOperation}
                        autoHideDuration={6000}
                        onClose={handleClose}
                      >
                        <Alert
                          onClose={handleClose}
                          severity="success"
                          variant="filled"
                          sx={{ width: "100%" }}
                        >
                          Your Are Subscribed Now. Thank You!
                        </Alert>
                      </Snackbar>
                    )}
                    <div className="col-md-auto mt-20">
                      <div className="row x-gap-25 y-gap-10 items-center justify-center">
                        {layoutGroup.groupFooter?.groupSocials?.map(
                          (social, index) => (
                            <div
                              className={`${
                                social?.socialUrl.includes("holidaycheck")
                                  ? "px-0"
                                  : ""
                              } col-auto`}
                              key={index}
                            >
                              <>
                                <Link
                                  to={social.socialUrl}
                                  className={`${
                                    social.socialClass ? "d-block" : "d-none"
                                  } text-white-60`}
                                >
                                  <i
                                    className={`${
                                      social.socialClass === "icon-tripadvisor"
                                        ? "fa fa-tripadvisor"
                                        : social.socialClass
                                    } text-11`}
                                  ></i>
                                </Link>
                                {social?.socialUrl.includes("holidaycheck") && (
                                  <Link
                                    to={social.socialUrl}
                                    className="d-block text-white-60"
                                  >
                                    <img
                                      src="Unternehmenslogo_HolidayCheck_AG_weiß.svg.png"
                                      width="80"
                                      alt="holidaycheck icon"
                                      style={{ filter: "invert(0.4)" }}
                                    />
                                  </Link>
                                )}
                              </>
                              )}
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="footer__bottom">
              <div className="container">
                <div className="row y-gap-30 justify-between md:justify-center items-center mx-auto col-lg-10">
                  <div className="col-md-auto">
                    <div className="text-15 text-center text-white-60">
                      {layoutGroup.groupFooter?.copyrights}
                    </div>
                  </div>

                  <div className="col-md-auto">
                    <Link className="footer__bottom_center">
                      <Link to="/" className="d-flex justify-center">
                        <img
                          src={layoutGroup.groupFooter?.groupLogo}
                          alt="logo"
                        />
                      </Link>
                    </Link>
                  </div>

                  <div className="col-md-auto">
                    <div className="row x-gap-25 y-gap-10 items-center justify-center   text-15 text-white-60 ">
                      <span className="text-center text-lg-end">
                        {" "}
                        Powered By
                        <Link
                          to="https://titegypt.com/"
                          className="ml-10"
                          target="_blank"
                        >
                          T.I.T Solutions
                        </Link>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </footer>
        </>
      )}
    </>
  );
};

export default LayoutGroup;
