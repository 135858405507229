import React, { useEffect, useState } from "react";
// import { Container, ProgressBar } from "react-bootstrap";

const Loader = () => {
  const [now, setNow] = useState(0);

  useEffect(() => {
    now <= 95 && setTimeout(() => setNow(now + 10), [100]);
  }, [now]);

  return (
    <>
      <div style={{ height: "100vh" }} className="d-flex align-items-center ">
        <div className="container mt-5 text-center">
          {" "}
          <div
            className="loader d-flex justify-content-center align-items-center"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div style={{ zIndex: "9999999", position: "fixed" }}>
              {" "}
              <img
                alt="loader"
                src="Spin.gif"
                width="100"
                // style={{ filter: "hue-rotate(185deg)" }}
                style={{ filter: "brightness(0.7)" }}
              />
              {/*<p className="fs-5 mt-3">Loading...</p> */}
            </div>
          </div>
          {/* <ProgressBar
            animated
            now={now}
            label={`${now}%`}
            style={{ height: "2px", color: "#000" }}  />{" "}*/}
        </div>
      </div>
    </>
  );
};

export default Loader;
