import React, { useEffect } from "react";
import { Fade, Slide } from "react-awesome-reveal";
import { Link, useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination, Navigation } from "swiper/core";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExpand,
  faUpRightAndDownLeftFromCenter,
} from "@fortawesome/free-solid-svg-icons";
import { Helmet } from "react-helmet";
import Loader from "../../Loader";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "@mui/material";
import { fetchMeetingsGroup } from "./../../data/meetingsGroup/meetingsGroupSlice";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import HomeIcon from "@mui/icons-material/Home";
SwiperCore.use([Pagination, Navigation]);

const MeetingAndEventsGroup = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery("(max-width:767px)");
  const isTablet = useMediaQuery("(max-width:1024px)");

  const { meetingsGroup, loading, error } = useSelector(
    (state) => state.meetingsGroup
  );
  useEffect(() => {
    dispatch(fetchMeetingsGroup());
  }, [dispatch]);
  return (
    <>
      {loading ? (
        <Loader />
      ) : error ? (
        <section
          style={{ backgroundColor: "#293839", padding: "200px 0 100px" }}
        >
          <h1 className="text-center text-light">{error}</h1>
        </section>
      ) : (
        <>
          <Helmet>
            <title>{meetingsGroup.pageDetails?.pageMetatagTitle}</title>

            <meta
              name="description"
              content={meetingsGroup.pageDetails?.pageMetatagDescription}
            />
          </Helmet>
          <section className="hero -type-10">
            <div
              className="hero__slider js-section-slider"
              data-gap="0"
              data-slider-cols="xl-1 lg-1 md-1 sm-1 base-1"
              data-nav-prev="js-sliderHero-prev"
              data-nav-next="js-sliderHero-next"
              data-pagination="js-sliderHero-pagination"
            >
              <div className="swiper-wrapper">
                <div className="swiper-slide">
                  <div className="hero__slide">
                    <div className="hero__bg">
                      <div className="img-ratio">
                        <img
                          src={
                            isMobile
                              ? meetingsGroup.pageDetails?.pageBannerMobile
                              : isTablet
                              ? meetingsGroup.pageDetails?.pageBannerTablet
                              : meetingsGroup.pageDetails?.pageBannerPC
                          }
                          alt={meetingsGroup.pageDetails?.pageTitle}
                          className="img-ratio"
                        />
                      </div>
                    </div>

                    <div className="hero__content text-center">
                      <div>
                        <Fade direction="up">
                          {" "}
                          <h1 className="hero__title capitalize text-white">
                            {meetingsGroup.pageDetails?.pageTitle}
                          </h1>
                        </Fade>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div
            role="presentation"
            className="breadcrumbs-wrapper py-3 bg-light-1"
          >
            <div className="container-fluid">
              <Breadcrumbs aria-label="breadcrumb" className="ps-lg-4">
                <Link
                  className="d-flex align-items-center"
                  underline="hover"
                  sx={{ display: "flex", alignItems: "center" }}
                  color="inherit"
                  to={`/`}
                >
                  <HomeIcon
                    sx={{ mr: 0.5, fontSize: "18px" }}
                    fontSize="inherit"
                  />
                  Home
                </Link>

                <Typography
                  sx={{ display: "flex", alignItems: "center" }}
                  color="text.primary"
                >
                  {meetingsGroup?.pageDetails.pageTitle}
                </Typography>
              </Breadcrumbs>
            </div>
          </div>
          <section className="layout-pt-lg">
            <div className="container">
              <div className="row justify-center text-center">
                <div className="col-xl-8 col-lg-10">
                  <Fade cascade direction="up">
                    <h2 className="text-40 uppercase mb-20">
                      {meetingsGroup.pageDetails?.pageTitle}
                    </h2>
                    <p className="text-25 md:text-25">
                      {meetingsGroup.pageDetails?.pageText}
                    </p>
                  </Fade>

                  {/* <div className="row y-gap-40 x-gap-60 md:x-gap-20 justify-center pt-60 md:pt-40">
                    <Fade direction="left" className="col-6">
                      <div className="text-center">
                        <i className="d-flex justify-center icon-phone text-40 mb-20"></i>
                        <Link
                          to={`tel:${meetingsGroup.mobile}`}
                          className="fw-500"
                        >
                          {meetingsGroup.mobile}
                        </Link>
                      </div>
                    </Fade>
                    <Fade direction="right" className="col-6">
                      <div className="text-center">
                        <i className="d-flex justify-center icon-email-2 text-40 mb-20"></i>
                        <Link
                          to={`mailto:${meetingsGroup.email}`}
                          className="fw-500"
                        >
                          {meetingsGroup.email}
                        </Link>
                      </div>
                    </Fade>
                  </div> */}
                </div>
              </div>
            </div>
          </section>

          <section className="layout-pt-lg layout-pb-lg" id="meetings-group">
            <div className="container">
              <div className="row y-gap-30 items-center justify-between">
                <div className="col-12">
                  <div className="relative overflow-hidden">
                    <Swiper
                      slidesPerView={1}
                      loop={true}
                      navigation
                      pagination={{
                        clickable: true,
                        el: ".js-slider1-pagination",
                        bulletClass: "pagination__item",
                        bulletActiveClass: "is-active",
                        renderBullet: (index, className) => {
                          return `<div class="${className}" tabindex="0" role="button" aria-label="Go to slide ${
                            index + 1
                          }"> </div>`;
                        },
                      }}
                      className="mySwiper"
                    >
                      {meetingsGroup.meetingEvent?.map((meeting, index) => (
                        <SwiperSlide
                          className="d-flex flex-wrap px-5 px-lg-0 justify-content-between align-items-center"
                          key={index}
                        >
                          <div className="ratio ratio-1:1 col-12 col-lg-5">
                            <Fade
                              direction="left"
                              duration="1000"
                              delay="0"
                              triggerOnce="true"
                            >
                              {" "}
                              <img
                                src={meeting.facilityPhoto}
                                alt={meeting.facilityName}
                                className="img-ratio"
                              />
                            </Fade>
                          </div>
                          <div className="col-12 col-xl-6 col-lg-6">
                            <Fade
                              direction="right"
                              duration="1000"
                              delay="0"
                              triggerOnce="true"
                            >
                              <i className="d-block icon-meeting text-30 mb-30"></i>
                              <p className="text-15 mb-10">
                                {meeting.hotelName}
                              </p>{" "}
                              <h3 className="text-40 md:text-30 lh-065">
                                {meeting.facilityName}
                              </h3>{" "}
                              <div className="d-flex flex-wrap x-gap-20 pt-40 md:pt-30">
                                <div className="d-flex items-center text-accent-1">
                                  Size: {meeting.meetingSize}
                                </div>
                                |
                                <div className="d-flex items-center text-accent-1">
                                  Widths: {meeting.meetingWidths}
                                </div>
                                |
                                <div className="d-flex items-center text-accent-1">
                                  Length: {meeting.meetingLength}
                                </div>
                                |
                                <div className="d-flex items-center text-accent-1">
                                  Celling Height: {meeting.meetingCellingHeight}
                                </div>
                                |
                                <div className="d-flex items-center text-accent-1">
                                  Capacity: {meeting.meetingCapacity}
                                </div>
                              </div>
                              <hr style={{ opacity: "0.1" }} />
                              <p className="mt-20">{meeting.facilitySummery}</p>
                              <Link
                                className="button -md -outline-accent-1 -type-2 mt-50 md:mt-40"
                                to={`${
                                  meeting.isMoreDetails
                                    ? `/${meeting.hotelUrl}/meetingandevents/${meeting.facilityUrl}`
                                    : `/${meeting.hotelUrl}/meetingandevents#${meeting.facilityUrl}`
                                }`}
                                onClick={() => {
                                  localStorage.setItem(
                                    "hotelUrl",
                                    meeting.hotelUrl
                                  );
                                }}
                              >
                                View More
                              </Link>
                            </Fade>
                          </div>
                        </SwiperSlide>
                      ))}
                    </Swiper>
                    <div className="pagination -type-1 -absolute -light js-slider1-pagination"></div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default MeetingAndEventsGroup;
