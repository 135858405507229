import React, { useEffect, useRef, useState } from "react";
import { Fade, Slide } from "react-awesome-reveal";
import { Link, useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";

import SwiperCore, { Pagination, Navigation } from "swiper/core";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { fetchFaqGroup } from "../../data/faqGroup/faqGroupSlice";
import Loader from "../../Loader";
import { Helmet } from "react-helmet";
import { useMediaQuery } from "@mui/material";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import HomeIcon from "@mui/icons-material/Home";
SwiperCore.use([Pagination, Navigation]);
const Faq = () => {
  const [openPanels, setOpenPanels] = useState([]);
  const dispatch = useDispatch();
  const { faqGroup, loading, error } = useSelector((state) => state.faqGroup);
  useEffect(() => {
    dispatch(fetchFaqGroup());
  }, [dispatch]);
  const isMobile = useMediaQuery("(max-width:767px)");
  const isTablet = useMediaQuery("(max-width:1024px)");

  const togglePanel = (index) => {
    if (openPanels.includes(index)) {
      // If panel is open, remove it from the array
      setOpenPanels(openPanels.filter((i) => i !== index));
    } else {
      // Otherwise add it to the array
      setOpenPanels([...openPanels, index]);
    }
  };
  return (
    <>
      {loading ? (
        <Loader />
      ) : error ? (
        <section
          style={{ backgroundColor: "#293839", padding: "200px 0 100px" }}
        >
          <h1 className="text-center text-light">{error}</h1>
        </section>
      ) : (
        <>
          <Helmet>
            <title>{faqGroup.pageDetails?.metatagTitle}</title>
            <meta
              name="description"
              content={faqGroup.pageDetails?.metatagDescription}
            />
          </Helmet>
          <section className="hero -type-10 banner-mobile">
            <div
              className="hero__slider js-section-slider"
              data-gap="0"
              data-slider-cols="xl-1 lg-1 md-1 sm-1 base-1"
              data-nav-prev="js-sliderHero-prev"
              data-nav-next="js-sliderHero-next"
              data-pagination="js-sliderHero-pagination"
            >
              <div className="swiper-wrapper">
                <div className="swiper-slide">
                  <div className="hero__slide">
                    <div className="hero__bg">
                      <div className="img-ratio">
                        {" "}
                        <img
                          src={
                            isMobile
                              ? faqGroup.pageDetails?.pageBannerMobile
                              : isTablet
                              ? faqGroup.pageDetails?.pageBannerTablet
                              : faqGroup.pageDetails?.pageBannerPC
                          }
                          alt={faqGroup.pageDetails?.pageTitle}
                          className="img-ratio"
                        />
                      </div>
                    </div>

                    <div className="hero__content text-center">
                      <div>
                        <Fade direction="up" delay="100">
                          <h1 className="hero__title capitalize text-white">
                            {faqGroup.pageDetails?.pageTitle}
                          </h1>
                        </Fade>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div
            role="presentation"
            className="breadcrumbs-wrapper py-3 bg-light-1"
          >
            <div className="container-fluid">
              <Breadcrumbs aria-label="breadcrumb" className="ps-lg-4">
                <Link
                  className="d-flex align-items-center"
                  underline="hover"
                  sx={{ display: "flex", alignItems: "center" }}
                  color="inherit"
                  to="/"
                >
                  <HomeIcon
                    sx={{ mr: 0.5, fontSize: "18px" }}
                    fontSize="inherit"
                  />
                  Home
                </Link>
                {/*  <Link
              underline="hover"
              sx={{ display: "flex", alignItems: "center" }}
              color="text.primary"
              href="/material-ui/getting-started/installation/"
            >
              Sub
            </Link>*/}
                <Typography
                  sx={{ display: "flex", alignItems: "center" }}
                  color="text.primary"
                >
                  FAQ
                </Typography>
              </Breadcrumbs>
            </div>
          </div>
          <section className="layout-pt-lg  ">
            <div className="container">
              <div className="row justify-center text-center">
                <div className="col-xl-8 col-lg-10">
                  <div className="text-15 uppercase mb-20">
                    {faqGroup.pageDetails?.pageTitle}
                  </div>
                  <h2 className="text-50 lg:text-40 md:text-30">
                    {faqGroup.pageDetails?.pageText}
                  </h2>
                </div>
              </div>
            </div>
          </section>
          <section className="layout-pt-lg layout-pb-lg">
            <div className="container">
              <div className="row justify-center">
                <div className="col-xl-8 col-lg-10">
                  <div className="accordion -type-1 row y-gap-10">
                    {faqGroup.groupFAQs?.map((item, index) => (
                      <div key={index} className="col-12">
                        <div className="accordion__item">
                          <div
                            className="accordion__button d-flex align-baseline justify-between px-30 py-20 bg-light-1"
                            onClick={() => togglePanel(index)}
                          >
                            <div className="text-sec text-24 fw-500 lh-1">
                              {item.groupFaqquestion}
                            </div>
                            <div className="accordion__icon">
                              {openPanels.includes(index) ? (
                                <FontAwesomeIcon icon={faMinus} />
                              ) : (
                                <FontAwesomeIcon icon={faPlus} />
                              )}
                            </div>
                          </div>
                          {openPanels.includes(index) && (
                            <div className="accordion__content">
                              <div className="px-30 py-30">
                                <p>{item.groupFaqanswers}</p>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default Faq;
