import React, { useEffect, useState } from "react";
import { Fade, Slide } from "react-awesome-reveal";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { fetchContactHotel } from "../../data/contactHotel/contactHotelSlice";
import { Helmet } from "react-helmet";
import {
  Alert,
  Breadcrumbs,
  Snackbar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Loader from "../../Loader";
import axios from "axios";
import {
  faEnvelope,
  faDiamondTurnRight,
  faHome,
  faLink,
  faLocationDot,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

import HomeIcon from "@mui/icons-material/Home";
const ContactUsHotels = () => {
  const hotelUrl = localStorage.getItem("hotelUrl");
  const dispatch = useDispatch();
  const isMobile = useMediaQuery("(max-width:767px)");
  const isTablet = useMediaQuery("(max-width:1024px)");
  const { contactHotel, loading, error } = useSelector(
    (state) => state.contactHotel
  );
  useEffect(() => {
    dispatch(fetchContactHotel(hotelUrl));
  }, [dispatch, hotelUrl]);
  const [formData, setFormData] = useState({
    customerName: "",
    customerEmail: "",
    customerPhone: "",
    customerMessage: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [sent, setSent] = useState(false);
  const [sentError, setSentError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      await axios.post(
        "https://api.hwaidakhotels.com/api/ContactUs/ContactUs-Submit",
        formData
      );

      setFormData({
        customerName: "",
        customerEmail: "",
        customerPhone: "",
        customerMessage: "",
      });
      setSent(true);
      setSentError(false);
    } catch (error) {
      setSentError(true);
      setSent(false);
      setErrorMsg(error);
    } finally {
      setIsSubmitting(false);
    }
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSent(false);
    setSentError(false);
  };
  return (
    <>
      {loading ? (
        <Loader />
      ) : error ? (
        <section
          style={{ backgroundColor: "#293839", padding: "200px 0 100px" }}
        >
          <h1 className="text-center text-light">{error}</h1>
        </section>
      ) : (
        <>
          <Helmet>
            <title>{contactHotel.pageDetails?.pageMetatagTitle}</title>
            <meta
              name="description"
              content={contactHotel.pageDetails?.pageMetatagDescription}
            />
          </Helmet>
          <section className="hero -type-10 banner-mobile">
            <div
              className="hero__slider js-section-slider"
              data-gap="0"
              data-slider-cols="xl-1 lg-1 md-1 sm-1 base-1"
              data-nav-prev="js-sliderHero-prev"
              data-nav-next="js-sliderHero-next"
              data-pagination="js-sliderHero-pagination"
            >
              <div className="swiper-wrapper">
                <div className="swiper-slide">
                  <div className="hero__slide">
                    <div className="hero__bg">
                      <divs className="img-ratio">
                        <img
                          src={
                            isMobile
                              ? contactHotel.pageDetails?.pageBannerMobile
                              : isTablet
                              ? contactHotel.pageDetails?.pageBannerTablet
                              : contactHotel.pageDetails?.pageBannerPC
                          }
                          alt={contactHotel.pageDetails?.pageTitle}
                          className="img-ratio"
                        />
                      </divs>
                    </div>

                    <div className="hero__content text-center">
                      <div>
                        <Fade direction="up" delay="100">
                          <h1 className="hero__title capitalize text-white">
                            {contactHotel.pageDetails?.pageTitle}
                          </h1>
                        </Fade>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div
            role="presentation"
            className="breadcrumbs-wrapper py-3 bg-light-1"
          >
            <div className="container-fluid">
              <Breadcrumbs aria-label="breadcrumb" className="ps-lg-4">
                <Link
                  className="d-flex align-items-center"
                  underline="hover"
                  sx={{ display: "flex", alignItems: "center" }}
                  color="inherit"
                  to={`/`}
                >
                  <HomeIcon
                    sx={{ mr: 0.5, fontSize: "18px" }}
                    fontSize="inherit"
                  />
                  Home
                </Link>
                <Link
                  underline="hover"
                  sx={{ display: "flex", alignItems: "center" }}
                  color="text.primary"
                  to={`/${hotelUrl}`}
                >
                  {hotelUrl === "aladdin-beach-resort"
                    ? "Aladdin Beach Resort "
                    : hotelUrl === "ali-baba-palace-hotel" ||
                      hotelUrl === "ali-baba-palace"
                    ? "Ali Baba Palace"
                    : "Jasmine Village"}
                </Link>
                <Typography
                  sx={{ display: "flex", alignItems: "center" }}
                  color="text.primary"
                >
                  Contact Us
                </Typography>
              </Breadcrumbs>
            </div>
          </div>
          <section className="layout-pt-lg layout-pb-lg">
            <div className="container">
              <div className="row justify-center text-center">
                <div className="col-xl-8 col-lg-10">
                  <div className="mb-30">
                    <svg
                      width="50"
                      height="50"
                      viewBox="0 0 50 50"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_632_5288)">
                        <path
                          d="M47.9511 0.57683C45.9935 -0.55357 43.4816 0.119689 42.3516 2.07726L36.7643 11.7548H24.7149C24.1755 11.7548 23.7383 12.1921 23.7383 12.7313C23.7383 13.2706 24.1755 13.7079 24.7149 13.7079H35.6366L31.5821 20.7308H8.78948C8.25011 20.7308 7.81289 21.1681 7.81289 21.7073C7.81289 22.2467 8.25011 22.6839 8.78948 22.6839H30.4543L28.199 26.5903H8.78948C8.25011 26.5903 7.81289 27.0276 7.81289 27.5669C7.81289 28.1061 8.25011 28.5435 8.78948 28.5435H27.795L27.3742 32.4498H8.78948C8.25011 32.4498 7.81289 32.8871 7.81289 33.4264C7.81289 33.9657 8.25011 34.403 8.78948 34.403H27.1638L27.1118 34.8853C27.0704 35.2697 27.2597 35.6424 27.5944 35.8356C27.7461 35.9232 27.9146 35.9665 28.0826 35.9665C28.2857 35.9665 28.488 35.9033 28.6588 35.7785L34.8944 31.2232C35.0043 31.1429 35.0961 31.0407 35.1641 30.9229L42.7745 17.7414V36.6703C42.7745 38.8585 40.9943 40.6388 38.8061 40.6388H16.1736C15.8246 40.6388 15.5022 40.8249 15.3278 41.1271L11.913 47.0418L8.49817 41.1271C8.32375 40.8249 8.00138 40.6388 7.65244 40.6388H5.92164C3.7334 40.6388 1.95317 38.8585 1.95317 36.6703V17.6764C1.95317 15.4882 3.7334 13.7079 5.92164 13.7079H15.9257C16.4651 13.7079 16.9023 13.2706 16.9023 12.7313C16.9023 12.192 16.4651 11.7548 15.9257 11.7548H5.92164C2.65642 11.7548 0 14.4112 0 17.6764V36.6703C0 39.9355 2.65642 42.592 5.92164 42.592H7.08856L11.0673 49.4832C11.2417 49.7854 11.5641 49.9715 11.913 49.9715C12.2619 49.9715 12.5843 49.7854 12.7587 49.4832L16.7374 42.592H38.8062C42.0714 42.592 44.7278 39.9355 44.7278 36.6703V17.6764C44.7278 16.8393 44.5503 16.0123 44.2107 15.2541L49.4516 6.17648C50.5818 4.21901 49.9087 1.70703 47.9511 0.57683ZM46.9745 2.26828C47.9994 2.85999 48.3517 4.17507 47.76 5.1999L47.202 6.16643L43.485 4.02037L44.043 3.05385C44.6347 2.02911 45.9497 1.67686 46.9745 2.26828ZM29.7203 28.9075L32.5315 30.5306L29.2904 32.8984L29.7203 28.9075ZM33.9609 29.1006L30.2439 26.9545L42.5084 5.71182L46.2254 7.85787L33.9609 29.1006Z"
                          fill="#122223"
                        ></path>
                        <path
                          d="M20.3203 13.708C20.5771 13.708 20.8291 13.6035 21.0117 13.4219C21.1934 13.2402 21.2979 12.9883 21.2979 12.7314C21.2979 12.4746 21.1934 12.2227 21.0117 12.041C20.8291 11.8595 20.5781 11.7549 20.3203 11.7549C20.0635 11.7549 19.8115 11.8594 19.6299 12.041C19.4482 12.2227 19.3447 12.4746 19.3447 12.7314C19.3447 12.9883 19.4481 13.2402 19.6299 13.4219C19.8125 13.6035 20.0635 13.708 20.3203 13.708Z"
                          fill="#122223"
                        ></path>
                      </g>
                      <defs>
                        <clippath id="clip0_632_5288">
                          <rect width="50" height="50" fill="white"></rect>
                        </clippath>
                      </defs>
                    </svg>
                  </div>

                  <div className="text-15 uppercase mb-20">Get In touch </div>
                  <h2 className="text-64 md:text-40 capitalize">
                    Leave us your info{" "}
                  </h2>
                  <p
                    className="lh-17 mt-30"
                    dangerouslySetInnerHTML={{
                      __html: `${contactHotel.pageDetails?.pageText}`,
                    }}
                  />

                  <form
                    className="contactForm row y-gap-30 pt-60"
                    onSubmit={handleSubmit}
                  >
                    <div className="col-md-12">
                      <div className="form-input">
                        <input
                          type="text"
                          name="customerName"
                          value={formData.customerName}
                          onChange={handleChange}
                          required
                        />
                        <label className="lh-1 text-16 text-light-1">
                          Your Name
                        </label>
                      </div>
                    </div>
                    <div className="col-12 col-lg-6">
                      <div className="form-input">
                        <input
                          type="email"
                          name="customerEmail"
                          value={formData.customerEmail}
                          onChange={handleChange}
                          required
                        />
                        <label className="lh-1 text-16 text-light-1">
                          Email
                        </label>
                      </div>
                    </div>
                    <div className="col-12 col-lg-6">
                      <div className="form-input">
                        <input
                          type="text"
                          name="customerPhone"
                          value={formData.customerPhone}
                          onChange={handleChange}
                          required
                        />
                        <label className="lh-1 text-16 text-light-1">
                          Phone Number
                        </label>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-input">
                        <textarea
                          name="customerMessage"
                          value={formData.customerMessage}
                          onChange={handleChange}
                          required
                          rows="8"
                        ></textarea>
                        <label className="lh-1">Message...</label>
                      </div>
                    </div>
                    <div className="col-12">
                      <button
                        className="button -md -type-2 w-1/1 bg-accent-2 -accent-1"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? "Loading..." : "SEND YOUR MESSAGE"}
                      </button>
                    </div>
                  </form>
                  {sentError ? (
                    <>
                      <Snackbar
                        open={sentError}
                        autoHideDuration={6000}
                        onClose={handleClose}
                      >
                        <Alert
                          onClose={handleClose}
                          severity="error"
                          variant="filled"
                          sx={{ width: "100%" }}
                        >
                          {errorMsg}
                        </Alert>
                      </Snackbar>
                    </>
                  ) : (
                    <Snackbar
                      open={sent}
                      autoHideDuration={6000}
                      onClose={handleClose}
                    >
                      <Alert
                        onClose={handleClose}
                        severity="success"
                        variant="filled"
                        sx={{ width: "100%" }}
                      >
                        Your Message Sent Successfully. Thank You!
                      </Alert>
                    </Snackbar>
                  )}
                </div>
              </div>
            </div>
          </section>
          <section
            className="relative layout-pt-lg layout-pb-lg mb-50  sm:pb-0    md:pt-0 bg-accent-1 d-flex flex-column-reverse"
            id="location"
          >
            <div className="sectionBg col-md-6 -left z-1 sm:pt-50 ">
              <iframe
                title="location"
                src={contactHotel.contactDetails?.hotelGoogleMap}
                width="100%"
                height={`${isMobile ? "350" : "100%"}`}
                style={{ border: "0" }}
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>

            <div className="container">
              <div className="row justify-end">
                <div className="col-md-5">
                  <h2 className="text-40 md:text-40 mt-20 text-white">
                    Contact Us:{" "}
                  </h2>
                  <div className="text-white mt-20">
                    <div className="mb-10">
                      <Link
                        to={`tel:${contactHotel.contactDetails?.hotelPhone}`}
                      >
                        <FontAwesomeIcon icon={faPhone} className="mr-10 " />
                        {contactHotel.contactDetails?.hotelPhone}
                      </Link>
                    </div>
                    <div className="mb-10">
                      <a
                        href={`https://wa.me/${contactHotel.contactDetails?.hotelWhatsAppNumber}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FontAwesomeIcon
                          icon={faWhatsapp}
                          className="fs-5 mr-10"
                        />
                        {contactHotel.contactDetails?.hotelWhatsAppNumber}
                      </a>
                    </div>
                    <div className="mb-10">
                      <Link
                        to={`mailto:${contactHotel.contactDetails?.hotelEmail}`}
                      >
                        <FontAwesomeIcon icon={faEnvelope} className="mr-10 " />
                        {contactHotel.contactDetails?.hotelEmail}
                      </Link>
                    </div>
                    <div>
                      <FontAwesomeIcon
                        icon={faLocationDot}
                        className="mr-10 "
                      />
                      {contactHotel.contactDetails?.hotelAddress}
                    </div>
                  </div>
                  {/*<Link
                    to="https://maps.app.goo.gl/WShh4hQpjrQU4Neq8"
                    target="_blank"
                  >
                    <button className="button -md -type-2 bg-accent-2 -light-1 mt-40">
                      GET DIRECTIONS
                    </button>
                  </Link> */}
                </div>
              </div>
              {contactHotel.hotelNearBy?.length > 0 && (
                <div className="row justify-end mt-20">
                  <div className="col-md-5">
                    <hr className="text-light " />
                    <h2 className="text-40 md:text-40  text-white">
                      {" "}
                      Hotel Near By:
                    </h2>
                    <div className="text-white mt-20">
                      {contactHotel.hotelNearBy?.map((l, index) => (
                        <>
                          <div key={index} className="mb-10 ">
                            <FontAwesomeIcon
                              icon={faDiamondTurnRight}
                              className="mr-10 "
                            />{" "}
                            {l.hotelNearByName}{" "}
                            {`(${l.hotelNearByDistance} ${l.hotelNearByDistanceUnit})`}{" "}
                          </div>
                        </>
                      ))}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </section>
        </>
      )}
    </>
  );
};
export default ContactUsHotels;
