import React, { useEffect } from "react";
import { Fade, Slide } from "react-awesome-reveal";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../../Loader";
import { Helmet } from "react-helmet";
import useMediaQuery from "@mui/material/useMediaQuery";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClipboard, faClock } from "@fortawesome/free-regular-svg-icons";
import { faInfo, faUtensils } from "@fortawesome/free-solid-svg-icons";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import HomeIcon from "@mui/icons-material/Home";
import { fetchAllInclusive } from "./../../data/all-inclusive/allInclusiveSlice";
const AllInclusive = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const hotelUrl = localStorage.getItem("hotelUrl");
  const isMobile = useMediaQuery("(max-width:767px)");
  const isTablet = useMediaQuery("(max-width:1024px)");

  const { allInclusive, loading, error } = useSelector(
    (state) => state.allInclusive
  );
  useEffect(() => {
    if (loading === true) {
      dispatch(fetchAllInclusive(hotelUrl));
    }
  }, [loading, dispatch, hotelUrl]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : error ? (
        <section
          style={{ backgroundColor: "#293839", padding: "200px 0 100px" }}
        >
          <h1 className="text-center text-light">{error}</h1>
        </section>
      ) : (
        <>
          <Helmet>
            <title>{allInclusive.pageDetails?.pageMetatagTitle}</title>
            <meta
              name="description"
              content={allInclusive.pageDetails?.pageMetatagDescription}
            />
          </Helmet>
          <section className="hero -type-10 banner-mobile">
            <div
              className="hero__slider js-section-slider"
              data-gap="0"
              data-slider-cols="xl-1 lg-1 md-1 sm-1 base-1"
              data-nav-prev="js-sliderHero-prev"
              data-nav-next="js-sliderHero-next"
              data-pagination="js-sliderHero-pagination"
            >
              <div className="swiper-wrapper">
                <div className="swiper-slide">
                  <div className="hero__slide">
                    <div className="hero__bg">
                      <div className="img-ratio">
                        <img
                          src={
                            isMobile
                              ? allInclusive.pageDetails?.pageBannerMobile
                              : isTablet
                              ? allInclusive.pageDetails?.pageBannerTablet
                              : allInclusive.pageDetails?.pageBannerPC
                          }
                          alt={allInclusive.pageDetails?.pageTitle}
                          className="img-ratio"
                        />
                      </div>
                    </div>

                    <div className="hero__content text-center">
                      <div>
                        <Fade direction="up" delay="100">
                          <h1 className="hero__title capitalize text-white">
                            {allInclusive.pageDetails?.pageTitle}
                          </h1>
                        </Fade>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div
            role="presentation"
            className="breadcrumbs-wrapper py-3 bg-light-1"
          >
            <div className="container-fluid">
              <Breadcrumbs aria-label="breadcrumb" className="ps-lg-4">
                <Link
                  className="d-flex align-items-center"
                  underline="hover"
                  sx={{ display: "flex", alignItems: "center" }}
                  color="inherit"
                  to={`/`}
                >
                  <HomeIcon
                    sx={{ mr: 0.5, fontSize: "18px" }}
                    fontSize="inherit"
                  />
                  Home
                </Link>
                <Link
                  underline="hover"
                  sx={{ display: "flex", alignItems: "center" }}
                  color="text.primary"
                  to={`/${hotelUrl}`}
                >
                  {hotelUrl === "aladdin-beach-resort"
                    ? "Aladdin Beach Resort "
                    : hotelUrl === "ali-baba-palace-hotel" ||
                      hotelUrl === "ali-baba-palace"
                    ? "Ali Baba Palace"
                    : "Jasmine Village"}
                </Link>

                <Link
                  underline="hover"
                  sx={{ display: "flex", alignItems: "center" }}
                  color="text.primary"
                  to={`/${hotelUrl}/dining`}
                >
                  Dining
                </Link>
                <Typography
                  sx={{ display: "flex", alignItems: "center" }}
                  color="text.primary"
                >
                  {allInclusive.pageDetails?.pageTitle}
                </Typography>
              </Breadcrumbs>
            </div>
          </div>
          <section className="layout-pt-md  ">
            <div className="container">
              <div className="row y-gap-40 x-gap-60 justify-between">
                <div className="col-12 col-xl-8 col-lg-8">
                  <Fade cascade direction="up" triggerOnce>
                    {" "}
                    <p
                      dangerouslySetInnerHTML={{
                        __html: `${allInclusive.pageDetails?.pageText}`,
                      }}
                    />
                  </Fade>
                </div>

                <div className="col-12 col-xl-4 col-lg-4">
                  <div className="sidebar -rooms-single px-30 py-30   border-1 shadow-1">
                    <div className="d-flex align-items-center">
                      {" "}
                      <svg
                        version="1.1"
                        id="Layer_1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        viewBox="0 0 122.88 117.22"
                        style={{
                          enableBackground: "new 0 0 122.88 117.22",
                          width: "30px",
                        }}
                        xmlSpace="preserve"
                        className="mr-10"
                      >
                        <style type="text/css">
                          {`.st0{fill-rule:evenodd;clip-rule:evenodd;}`}
                        </style>
                        <g>
                          <path
                            className="st0"
                            d="M40.09,59.7l-0.15,9.21h16.63c3.52,0,6.4,2.88,6.4,6.4v6.4h-0.07v25.04c0,3.52-2.88,6.4-6.4,6.4h-6.4V84.03 c0-2.64,0.5-2.32-2.05-2.32H31.58v-0.04h-8.21c-5.3,0-9.63-4.33-9.63-9.63V40.51c0-5.39,4.41-9.79,9.79-9.79h4.85 c6.82,0,8.3,3.18,9.92,6.59l8.67,4.16c5.93,2.67,4.4,3.2,8.42-1.78l4.48-5.56l-4.97-2.92c-0.94,0.18-2.12,0.02-3.26-0.51 c-2.11-0.99-3.31-2.89-2.67-4.24c0.63-1.35,2.86-1.65,4.97-0.66c1.8,0.85,2.94,2.36,2.83,3.62l4.8,2.82 c3.46-2.25,11.3,1.83,7.38,8.02L61.37,50.6c-3.16,4.31-7.36,4.92-12.44,2.8l-8.84-3.11V59.7L40.09,59.7z M122.31,57.15H90.29 l2.49-3.04c0.39,0.3,0.83,0.54,1.29,0.72c0.56,0.21,1.18,0.33,1.81,0.33c1.43,0,2.72-0.59,3.66-1.54c0.94-0.95,1.51-2.25,1.51-3.7 c0-1.44-0.58-2.75-1.51-3.7c-0.94-0.95-2.23-1.54-3.66-1.54c-1.43,0-2.72,0.59-3.66,1.54c-0.85,0.86-1.41,2.03-1.5,3.32 c-7.84,0-15.69,0-23.53,0v0c-0.11,0-0.22,0.04-0.31,0.12c-0.18,0.17-0.18,0.45-0.01,0.63l5.98,6.85H59.7 c-0.31,0-0.57,0.26-0.57,0.57v8.16c0,0.31,0.26,0.57,0.57,0.57c10.91,0,16.21,0,27.13,0v50c0,0.41,0.34,0.75,0.75,0.75h0.29h0.02 h8.01h0.02h0.29c0.41,0,0.75-0.34,0.75-0.75v-50c7.2,0,18.15,0,25.35,0c0.31,0,0.57-0.26,0.57-0.57v-8.16 C122.88,57.4,122.62,57.15,122.31,57.15L122.31,57.15z M1.78,50.66h4.67c0.95,0,1.58,0.79,1.73,1.73 c1.77,11.12,2.72,22.25,2.67,33.37h31.95c1.26,0,2.29,1.03,2.29,2.29v4.63h-1.83v23.68c0,0.38-0.32,0.7-0.7,0.7h-6.12 c-0.38,0-0.7-0.31-0.7-0.7v-10.3H9.59c-0.38,3.14-0.85,6.28-1.41,9.42c-0.17,0.94-0.78,1.73-1.73,1.73H1.78 c-0.95,0-2-0.82-1.73-1.73c6.16-21.43,4.87-42.38,0-63.09C-0.18,51.46,0.82,50.66,1.78,50.66L1.78,50.66z M35.73,102.87V92.69 H10.75c-0.14,4.33-0.44,5.85-0.9,10.19H35.73L35.73,102.87z M28.07,0c7.4,0,13.4,6,13.4,13.4c0,7.4-6,13.4-13.4,13.4 c-7.4,0-13.4-6-13.4-13.4C14.68,6,20.68,0,28.07,0L28.07,0z"
                          />
                        </g>
                      </svg>
                      <h2 className="text-30">Dining:</h2>
                    </div>
                    <div className="row x-gap-50 y-gap-20 justify-between pt-40">
                      <div className="col-sm-12">
                        {allInclusive.restauransList?.map(
                          (restaurant, index) => (
                            <li className="amenities " key={index}>
                              <img
                                className="mb-3  me-3"
                                style={{ borderRadius: "5px", width: "20%" }}
                                alt={restaurant.restaurantName}
                                src={restaurant.restaurantPhoto}
                              />
                              <Link
                                to={`/${restaurant.hotelUrl}/dining/${restaurant.restaurantUrl}`}
                              >
                                {restaurant.restaurantName}
                              </Link>
                            </li>
                          )
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="line -horizontal bg-border mt-50 mb-50"></div>
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default AllInclusive;
