import React, { Fragment, useEffect, useRef, useState } from "react";
import { Fade, Slide } from "react-awesome-reveal";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination, Navigation } from "swiper/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faSquareArrowUpRight,
} from "@fortawesome/free-solid-svg-icons";
import { faCheckCircle, faClock } from "@fortawesome/free-regular-svg-icons";
import { fetchFacilityDetails } from "../../data/facilities/facilityDetailsSlice";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "@mui/material";
import { Helmet } from "react-helmet";
import Loader from "../../Loader";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import HomeIcon from "@mui/icons-material/Home";
SwiperCore.use([Pagination, Navigation]);
const FacilitiesDetails = () => {
  const hotelUrl = localStorage.getItem("hotelUrl");
  const navigate = useNavigate();
  const swiperRef = useRef(null);
  const swiperRef2 = useRef(null);
  const { facilityUrl } = useParams();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery("(max-width:767px)");
  const isTablet = useMediaQuery("(max-width:1024px)");
  const { facilityDetails, loading, error } = useSelector(
    (state) => state.facilityDetails
  );

  useEffect(() => {
    dispatch(fetchFacilityDetails({ hotelUrl, facilityUrl }));
  }, [dispatch, facilityUrl, hotelUrl]);
  const setSwiperRef = (swiper) => {
    swiperRef.current = swiper;
  };
  const setSwiperRef2 = (swiper) => {
    swiperRef2.current = swiper;
  };
  const handlePrev = () => {
    if (swiperRef.current) {
      swiperRef.current.slidePrev();
    }
  };

  // Custom handler to navigate to next slide
  const handleNext = () => {
    if (swiperRef.current) {
      swiperRef.current.slideNext();
    }
  };
  const slidesData = [
    {
      imgSrc: "fac1.jpg",
      iconClass: "icon-restaurant",
      title: "Restaurants & Bars",
    },
    {
      imgSrc: "img/cards/8/2.png",
      iconClass: "icon-rocks",
      title: "Spa & Wellness",
    },
    {
      imgSrc: "fac3.jpg",
      iconClass: "icon-gym",
      title: "Fitness Center",
    },
    {
      imgSrc: "fac4.jpg",
      iconClass: "icon-check",
      title: "Kids Club",
    },
    {
      imgSrc: "fac5.jpg",
      iconClass: "icon-chevron-down",
      title: "Aqua Park",
    },
  ];
  const [activeIndex, setActiveIndex] = useState(0);

  const updateActiveIndex = (index) => {
    setActiveIndex(index);
    if (swiperRef2.current) {
      swiperRef2.current.slideTo(index);
    }
  };
  return (
    <>
      {loading ? (
        <Loader />
      ) : error ? (
        <section
          style={{ backgroundColor: "#293839", padding: "200px 0 100px" }}
        >
          <h1 className="text-center text-light">{error}</h1>
        </section>
      ) : (
        <>
          <Helmet>
            <title>
              {facilityDetails.metatagTitle
                ? facilityDetails.metatagTitle
                : facilityDetails.facilityName}
            </title>
            <meta
              name="description"
              content={
                facilityDetails.metatagDescription
                  ? facilityDetails.metatagDescription
                  : ""
              }
            />
          </Helmet>
          <section className="hero -type-10 banner-mobile">
            <div
              className="hero__slider js-section-slider"
              data-gap="0"
              data-slider-cols="xl-1 lg-1 md-1 sm-1 base-1"
              data-nav-prev="js-sliderHero-prev"
              data-nav-next="js-sliderHero-next"
              data-pagination="js-sliderHero-pagination"
            >
              <div className="swiper-wrapper">
                <div className="swiper-slide">
                  <div className="hero__slide">
                    <div className="hero__bg">
                      <div className="img-ratio">
                        {" "}
                        <img
                          src={
                            isMobile
                              ? facilityDetails.facilityBannerMobile
                              : isTablet
                              ? facilityDetails.facilityBannerTablet
                              : facilityDetails.facilityBanner
                          }
                          alt={facilityDetails.facilityName}
                          className="img-ratio"
                        />
                      </div>
                    </div>

                    <div className="hero__content text-center">
                      <div>
                        <Fade direction="up" delay="100">
                          <h1 className="hero__title capitalize text-white">
                            {facilityDetails.facilityName}
                          </h1>
                        </Fade>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div
            role="presentation"
            className="breadcrumbs-wrapper py-3 bg-light-1"
          >
            <div className="container-fluid">
              <Breadcrumbs aria-label="breadcrumb" className="ps-lg-4">
                <Link
                  className="d-flex align-items-center"
                  underline="hover"
                  sx={{ display: "flex", alignItems: "center" }}
                  color="inherit"
                  to={`/`}
                >
                  <HomeIcon
                    sx={{ mr: 0.5, fontSize: "18px" }}
                    fontSize="inherit"
                  />
                  Home
                </Link>
                <Link
                  underline="hover"
                  sx={{ display: "flex", alignItems: "center" }}
                  color="text.primary"
                  to={`/${hotelUrl}`}
                >
                  {hotelUrl === "aladdin-beach-resort"
                    ? "Aladdin Beach Resort "
                    : hotelUrl === "ali-baba-palace-hotel" ||
                      hotelUrl === "ali-baba-palace"
                    ? "Ali Baba Palace"
                    : "Jasmine Village"}
                </Link>
                <Link
                  underline="hover"
                  sx={{ display: "flex", alignItems: "center" }}
                  color="text.primary"
                  to={`/${hotelUrl}/facilities`}
                >
                  Facilities
                </Link>
                <Typography
                  sx={{ display: "flex", alignItems: "center" }}
                  color="text.primary"
                >
                  {facilityDetails.facilityName}
                </Typography>
              </Breadcrumbs>
            </div>
          </div>
          <section className="layout-pt-md  ">
            <div className="container">
              <div className="row y-gap-40 x-gap-60 justify-between">
                <div className="col-12 col-xl-8 col-lg-8">
                  <h2 className="text-40">{facilityDetails.facilityName}</h2>

                  <div className="line -horizontal bg-border mt-50 mb-50"></div>

                  <p
                    dangerouslySetInnerHTML={{
                      __html: `${facilityDetails.facilityDetails}`,
                    }}
                  />
                </div>

                <div className="col-12 col-xl-4 col-lg-4">
                  <div className="sidebar -rooms-single px-30 py-30   border-1 shadow-1">
                    <h2 className="text-40">Facilities</h2>
                    <div className="row x-gap-50 y-gap-20 justify-between pt-40">
                      <div className="col-sm-12">
                        <ul>
                          {facilityDetails.otherFacility?.map((facility) => (
                            <Fragment key={facility.facilityUrl}>
                              <li>
                                <Link
                                  to={`${
                                    facility.isMoreDetails
                                      ? `/${facility.hotelUrl}/facilities/${facility.facilityUrl}`
                                      : `/${facility.hotelUrl}/facilities#${facility.facilityUrl}`
                                  }`}
                                >
                                  <FontAwesomeIcon
                                    icon={faSquareArrowUpRight}
                                    className="mr-10"
                                  />
                                  {facility.facilityName}
                                </Link>
                              </li>
                              <hr style={{ color: "#ccc" }} />
                            </Fragment>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="line -horizontal bg-border mt-50 mb-50"></div>
            </div>
          </section>
          {facilityDetails.facilityGallery?.length > 0 && (
            <section className="layout-pt-lg layout-pb-lg bg-light-1">
              <div className="container">
                <div className="row justify-center text-center mb-100 sm:mb-50">
                  <div className="col-auto">
                    <div className="text-15 uppercase mb-30 sm:mb-10">
                      EXPLORE
                    </div>
                    <h2 className="text-64 md:text-40">Gallery</h2>
                  </div>
                </div>
              </div>

              <div className="relative">
                <div className="container">
                  <div className="row justify-center">
                    <div className="col-lg-10 col-md-11">
                      <Swiper
                        className="overflow-visible"
                        modules={[Navigation]}
                        spaceBetween={180}
                        slidesPerView={1}
                        onSwiper={setSwiperRef}
                      >
                        {facilityDetails.facilityGallery?.map(
                          (image, index) => (
                            <SwiperSlide key={index}>
                              <div className="ratio ratio-16:9">
                                <img
                                  src={image.photoFile}
                                  alt=""
                                  className="img-ratio"
                                />
                              </div>
                            </SwiperSlide>
                          )
                        )}
                      </Swiper>
                    </div>
                  </div>
                </div>

                <div className="navAbsolute -type-3">
                  <button
                    className="size-80 flex-center bg-accent-1-50 blur-1 rounded-full js-slider1-prev"
                    onClick={() => swiperRef.current?.slidePrev()} // Call handlePrev when prev button is clicked
                  >
                    <i className="icon-arrow-left text-24 text-white"></i>
                  </button>
                  <button
                    className="size-80 flex-center bg-accent-1-50 blur-1 rounded-full js-slider1-next"
                    onClick={() => swiperRef.current?.slideNext()} // Call handleNext when next button is clicked
                  >
                    <i className="icon-arrow-right text-24 text-white"></i>
                  </button>
                </div>
              </div>
            </section>
          )}
          <section className="pt-50 layout-pb-lg bg-light-1">
            <div className="container">
              <div className="row y-gap-30 justify-between items-end">
                <div className="col-auto">
                  <Fade cascade direction="up" triggerOnce>
                    <h2 className="text-64 md:text-40">Other Facilities</h2>
                  </Fade>
                </div>

                <div className="col-auto">
                  <div className="pagination -type-1 -accent-1">
                    {facilityDetails.otherFacility?.map(
                      (facility, index) =>
                        index <= 1 && (
                          <div
                            key={facility.facilityUrl}
                            className={`pagination__item ${
                              activeIndex === index ? "is-active" : ""
                            }`}
                            tabIndex={index}
                            role="button"
                            aria-label={`Go to slide ${index}`}
                            aria-current={
                              activeIndex === index ? "true" : "false"
                            }
                            onClick={() => updateActiveIndex(index)}
                          ></div>
                        )
                    )}
                  </div>
                </div>
              </div>

              <div className="pt-100 sm:pt-50">
                <Swiper
                  modules={[Pagination, Navigation]}
                  spaceBetween={30}
                  slidesPerView={3}
                  breakpoints={{
                    300: { slidesPerView: 1 },
                    640: { slidesPerView: 1 },
                    768: { slidesPerView: 2 },
                    1024: { slidesPerView: 3 },
                  }}
                  onSlideChange={(swiper) => setActiveIndex(swiper.activeIndex)}
                  onSwiper={(swiper) => (swiperRef2.current = swiper)}
                >
                  {facilityDetails.otherFacility?.map((facility, index) => (
                    <SwiperSlide key={index}>
                      <div className="baseCard -type-1 -padding-lg">
                        <div
                          className={`baseCard__image ratio ratio-45:54 rounded-16`}
                        >
                          <Slide
                            className="img-ratio rounded-16"
                            triggerOnce="true"
                          >
                            <img
                              src={facility.facilityPhotoHome}
                              alt={facility.facilityName}
                              className="img-ratio rounded-16"
                            />
                          </Slide>
                        </div>
                        <Link
                          to={`${
                            facility.isMoreDetails
                              ? `/${facility.hotelUrl}/facilities/${facility.facilityUrl}`
                              : `/${facility.hotelUrl}/facilities#${facility.facilityUrl}`
                          }`}
                          className="baseCard__content d-flex flex-column justify-end text-center"
                        >
                          <div
                            className={`${facility.icon} text-40 text-white mb-20`}
                          ></div>
                          <h4 className="text-30 md:text-25 text-white">
                            {facility.facilityName}
                          </h4>
                        </Link>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default FacilitiesDetails;
