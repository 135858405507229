import React, { useEffect } from "react";
import { Fade, Slide } from "react-awesome-reveal";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Breadcrumbs, Typography, useMediaQuery } from "@mui/material";
import Loader from "../../Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fetchContactGroup } from "./../../data/contactGroup/contactGroupSlice";

import HomeIcon from "@mui/icons-material/Home";
import {
  faEnvelope,
  faLocationDot,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";

const ContactUsGroup = () => {
  const hotelUrl = localStorage.getItem("hotelUrl");
  const dispatch = useDispatch();
  const isMobile = useMediaQuery("(max-width:767px)");
  const isTablet = useMediaQuery("(max-width:1024px)");
  const { contactGroup, loading, error } = useSelector(
    (state) => state.contactGroup
  );
  useEffect(() => {
    dispatch(fetchContactGroup());
  }, [dispatch]);
  return (
    <>
      {loading ? (
        <Loader />
      ) : error ? (
        <section
          style={{ backgroundColor: "#293839", padding: "200px 0 100px" }}
        >
          <h1 className="text-center text-light">{error}</h1>
        </section>
      ) : (
        <>
          <Helmet>
            <title>{contactGroup.pageDetails?.pageMetatagTitle}</title>
            <meta
              name="description"
              content={contactGroup.pageDetails?.pageMetatagDescription}
            />
          </Helmet>
          <section className="hero -type-10 banner-mobile">
            <div
              className="hero__slider js-section-slider"
              data-gap="0"
              data-slider-cols="xl-1 lg-1 md-1 sm-1 base-1"
              data-nav-prev="js-sliderHero-prev"
              data-nav-next="js-sliderHero-next"
              data-pagination="js-sliderHero-pagination"
            >
              <div className="swiper-wrapper">
                <div className="swiper-slide">
                  <div className="hero__slide">
                    <div className="hero__bg">
                      <div className="img-ratio">
                        <img
                          src={
                            isMobile
                              ? contactGroup.pageDetails?.pageBannerMobile
                              : isTablet
                              ? contactGroup.pageDetails?.pageBannerTablet
                              : contactGroup.pageDetails?.pageBannerPC
                          }
                          alt={contactGroup.pageDetails?.pageTitle}
                          className="img-ratio"
                        />
                      </div>
                    </div>

                    <div className="hero__content text-center">
                      <div>
                        <Fade direction="up" delay="100">
                          <h1 className="hero__title capitalize text-white">
                            {contactGroup.pageDetails?.pageTitle}
                          </h1>
                        </Fade>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div
            role="presentation"
            className="breadcrumbs-wrapper py-3 bg-light-1"
          >
            <div className="container-fluid">
              <Breadcrumbs aria-label="breadcrumb" className="ps-lg-4">
                <Link
                  className="d-flex align-items-center"
                  underline="hover"
                  sx={{ display: "flex", alignItems: "center" }}
                  color="inherit"
                  to="/"
                >
                  <HomeIcon
                    sx={{ mr: 0.5, fontSize: "18px" }}
                    fontSize="inherit"
                  />
                  Home
                </Link>
                {/*  <Link
              underline="hover"
              sx={{ display: "flex", alignItems: "center" }}
              color="text.primary"
              href="/material-ui/getting-started/installation/"
            >
              Sub
            </Link>*/}
                <Typography
                  sx={{ display: "flex", alignItems: "center" }}
                  color="text.primary"
                >
                  Contact Us
                </Typography>
              </Breadcrumbs>
            </div>
          </div>
          <section className="layout-pt-lg  ">
            <div className="container">
              <div className="row justify-center text-center">
                <div className="col-xl-8 col-lg-10">
                  <h2 className="text-64 md:text-40 capitalize">
                    {contactGroup.pageDetails?.pageTitle}
                  </h2>
                  <p
                    className="lh-17 mt-30"
                    dangerouslySetInnerHTML={{
                      __html: `${contactGroup.pageDetails?.pageText}`,
                    }}
                  />
                </div>
              </div>
            </div>
          </section>
          <section className="  layout-pt-lg layout-pb-lg mb-50 md:pt-20  ">
            {" "}
            <div className="container-fluid">
              <div className="col-12 col-lg-11 d-flex flex-wrap mx-auto   justify-center align-items-center ">
                {contactGroup.hotelsContact?.map((contact, index) => (
                  <div
                    className={`contact-box col-12 col-lg-3 mb-10 mt-10 mt-lg-0 mb-lg-0 ${
                      index === 1 && "mx-5"
                    }`}
                  >
                    <iframe
                      className="w-100"
                      height="350"
                      title={`${contact.hotelName}location`}
                      src={contact.hotelGoogleMap}
                      style={{ border: "0" }}
                      allowfullscreen=""
                      loading="lazy"
                    />
                    <hr />
                    <h2 className="text-center">{contact.hotelName}</h2>
                    <hr />

                    <div className="mb-5">
                      <Link to={`tel:${contact.hotelPhone}`}>
                        <FontAwesomeIcon icon={faPhone} className="mr-10" />{" "}
                        {contact.hotelPhone}
                      </Link>
                    </div>
                    <div className="mb-5">
                      <Link to={`mailto:${contact.hotelEmail}`}>
                        <FontAwesomeIcon icon={faEnvelope} className="mr-10" />
                        {contact.hotelEmail}
                      </Link>
                    </div>
                    <div className="mb-5">
                      <Link to={contact.hotelGetDirectionLink} target="_blank">
                        <FontAwesomeIcon
                          icon={faLocationDot}
                          className="mr-10"
                        />
                        {contact.hotelAddress}
                      </Link>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};
export default ContactUsGroup;
