import React, { useEffect } from "react";
import { Fade, Slide } from "react-awesome-reveal";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../../Loader";
import { Helmet } from "react-helmet";
import useMediaQuery from "@mui/material/useMediaQuery";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClipboard, faClock } from "@fortawesome/free-regular-svg-icons";
import { faInfo, faUtensils } from "@fortawesome/free-solid-svg-icons";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import HomeIcon from "@mui/icons-material/Home";
import { fetchDiningType } from "./../../data/diningtype/diningTypeSlice";
const DiningType = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const hotelUrl = localStorage.getItem("hotelUrl");
  const isMobile = useMediaQuery("(max-width:767px)");
  const isTablet = useMediaQuery("(max-width:1024px)");
  const { filterBy } = useParams();

  const { diningType, loading, error } = useSelector(
    (state) => state.diningType
  );
  useEffect(() => {
    dispatch(fetchDiningType({ hotelUrl, filterBy }));
  }, [dispatch, hotelUrl, filterBy]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : error ? (
        <section
          style={{ backgroundColor: "#293839", padding: "200px 0 100px" }}
        >
          <h1 className="text-center text-light">{error}</h1>
        </section>
      ) : (
        <>
          <Helmet>
            <title>{diningType.pageDetails?.pageMetatagTitle}</title>
            <meta
              name="description"
              content={diningType.pageDetails?.pageMetatagDescription}
            />
          </Helmet>
          <section className="hero -type-10 banner-mobile">
            <div
              className="hero__slider js-section-slider"
              data-gap="0"
              data-slider-cols="xl-1 lg-1 md-1 sm-1 base-1"
              data-nav-prev="js-sliderHero-prev"
              data-nav-next="js-sliderHero-next"
              data-pagination="js-sliderHero-pagination"
            >
              <div className="swiper-wrapper">
                <div className="swiper-slide">
                  <div className="hero__slide">
                    <div className="hero__bg">
                      <div className="img-ratio">
                        <img
                          src={
                            isMobile
                              ? diningType.pageDetails?.pageBannerMobile
                              : isTablet
                              ? diningType.pageDetails?.pageBannerTablet
                              : diningType.pageDetails?.pageBannerPC
                          }
                          alt={diningType.pageDetails?.pageTitle}
                          className="img-ratio"
                        />
                      </div>
                    </div>

                    <div className="hero__content text-center">
                      <div>
                        <Fade direction="up" delay="100">
                          <h1 className="hero__title capitalize text-white">
                            {diningType.pageDetails?.pageTitle}
                          </h1>
                        </Fade>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div
            role="presentation"
            className="breadcrumbs-wrapper py-3 bg-light-1"
          >
            <div className="container-fluid">
              <Breadcrumbs aria-label="breadcrumb" className="ps-lg-4">
                <Link
                  className="d-flex align-items-center"
                  underline="hover"
                  sx={{ display: "flex", alignItems: "center" }}
                  color="inherit"
                  to={`/`}
                >
                  <HomeIcon
                    sx={{ mr: 0.5, fontSize: "18px" }}
                    fontSize="inherit"
                  />
                  Home
                </Link>
                <Link
                  underline="hover"
                  sx={{ display: "flex", alignItems: "center" }}
                  color="text.primary"
                  to={`/${hotelUrl}`}
                >
                  {hotelUrl === "aladdin-beach-resort"
                    ? "Aladdin Beach Resort "
                    : hotelUrl === "ali-baba-palace-hotel" ||
                      hotelUrl === "ali-baba-palace"
                    ? "Ali Baba Palace"
                    : "Jasmine Village"}
                </Link>

                <Typography
                  sx={{ display: "flex", alignItems: "center" }}
                  color="text.primary"
                >
                  {diningType.pageDetails?.pageTitle}
                </Typography>
              </Breadcrumbs>
            </div>
          </div>
          <section className="layout-pt-lg layout-pb-lg">
            <div className="container">
              <div className="row justify-center text-center">
                <div className="col-xl-6 col-lg-8 col-md-10">
                  <Fade direction="up" cascade>
                    <div className="text-15 uppercase mb-30 sm:mb-10">
                      OUR Dining
                    </div>
                    <h2 className="text-64 md:text-40 lh-11">
                      {diningType.pageDetails?.pageTitle}
                    </h2>
                  </Fade>
                  <p className="mt-40">{diningType.pageDetails?.pageText}</p>
                </div>
              </div>
            </div>
            <div className="px-60 md:px-15">
              {diningType.restauransList?.map((restaurant, index) =>
                index % 2 === 0 ? (
                  <div
                    className="roomCard -type-2 "
                    key={index}
                    id={restaurant.restaurantUrl}
                  >
                    <div className="row  flex-column-reverse flex-lg-row x-gap-30 y-gap-40 items-center pt-100 sm:pt-50">
                      <div className="  col-lg-5  col-md-6">
                        <div className="roomCard__content">
                          <div className="d-flex justify-between items-end">
                            <h3 className="roomCard__title lh-065 text-40 md:text-40">
                              {restaurant.restaurantName}
                            </h3>
                          </div>

                          <div className="d-flex x-gap-20 pt-40 md:pt-30 flex-wrap">
                            {restaurant.restaurantCuisine && (
                              <>
                                <div className="d-flex items-center text-accent-1 w-auto">
                                  <i className="icon-restaurant text-20 mr-10"></i>
                                  Cuisine: {restaurant.restaurantCuisine}
                                </div>
                              </>
                            )}

                            <div className="d-flex items-center text-accent-1 w-auto">
                              {restaurant.dressCode && (
                                <>
                                  <i className="icon-guest text-20 mr-10"></i>
                                  Dress Code: {restaurant.dressCode}
                                </>
                              )}
                            </div>

                            <div className="d-flex items-center text-accent-1 w-auto">
                              {restaurant.restaurantOpenFor && (
                                <>
                                  {" "}
                                  <FontAwesomeIcon
                                    icon={faUtensils}
                                    className="mr-10"
                                  />
                                  Open For: {restaurant.restaurantOpenFor}
                                </>
                              )}
                            </div>
                            <div className="d-flex items-center text-accent-1 w-auto">
                              {restaurant.restaurantMenu && (
                                <>
                                  {" "}
                                  <FontAwesomeIcon
                                    icon={faClipboard}
                                    className="mr-10"
                                  />
                                  Menu: {restaurant.restaurantMenu}
                                </>
                              )}
                            </div>
                            <div className="d-flex items-center text-accent-1 w-auto">
                              {restaurant.restaurantNote && (
                                <>
                                  {" "}
                                  <FontAwesomeIcon
                                    icon={faInfo}
                                    className="mr-10"
                                  />
                                  {restaurant.restaurantNote}
                                </>
                              )}
                            </div>
                          </div>

                          <p className="mt-40 md:mt-30">
                            {restaurant.restaurantSummery}
                          </p>
                          <p className="fw-bold mt-3">
                            <FontAwesomeIcon icon={faClock} /> Opening Hours:
                          </p>
                          <div
                            className="mt-2"
                            dangerouslySetInnerHTML={{
                              __html: `${restaurant.openinghours}`,
                            }}
                          />
                          {restaurant.isMoreDetails && (
                            <button
                              className="button -md -type-2 -outline-accent-1 mt-40 md:mt-30"
                              onClick={() =>
                                navigate(
                                  `/${restaurant.hotelUrl}/dining/${restaurant.restaurantUrl}`
                                )
                              }
                            >
                              More DETAIL{" "}
                            </button>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-6 offset-lg-1 col-md-6">
                        <div>
                          <Link
                            to={`${
                              restaurant.isMoreDetails
                                ? `/${restaurant.hotelUrl}/dining/${restaurant.restaurantUrl}`
                                : `/${restaurant.hotelUrl}/dining#${restaurant.restaurantUrl}`
                            }`}
                            className="roomCard__image -no-rounded ratio ratio-68:50"
                          >
                            <Slide className="img-ratio">
                              <img
                                src={restaurant.restaurantPhoto}
                                alt=""
                                className="img-ratio"
                              />
                            </Slide>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div
                    className="roomCard -type-2"
                    key={index}
                    id={restaurant.restaurantUrl}
                  >
                    <div className="row x-gap-30 y-gap-40  flex-column flex-lg-row items-center pt-100 sm:pt-50">
                      <div className="col-lg-6   col-md-6">
                        <div>
                          <Link
                            to={`${
                              restaurant.isMoreDetails
                                ? `/${restaurant.hotelUrl}/dining/${restaurant.restaurantUrl}`
                                : `/${restaurant.hotelUrl}/dining#${restaurant.restaurantUrl}`
                            }`}
                            className="roomCard__image -no-rounded ratio ratio-68:50"
                          >
                            <Slide className="img-ratio">
                              <img
                                src={restaurant.restaurantPhoto}
                                alt=""
                                className="img-ratio"
                              />
                            </Slide>
                          </Link>
                        </div>
                      </div>
                      <div className="  col-lg-5 offset-lg-1 col-md-6">
                        <div className="roomCard__content">
                          <div className="d-flex justify-between items-end">
                            <h3 className="roomCard__title lh-065 text-40 md:text-40">
                              {restaurant.restaurantName}
                            </h3>
                          </div>

                          <div className="d-flex x-gap-20 pt-40 md:pt-30 flex-wrap">
                            {restaurant.restaurantCuisine && (
                              <>
                                <div className="d-flex items-center text-accent-1 w-auto">
                                  <i className="icon-restaurant text-20 mr-10"></i>
                                  Cuisine: {restaurant.restaurantCuisine}
                                </div>
                              </>
                            )}

                            <div className="d-flex items-center text-accent-1 w-auto">
                              {restaurant.dressCode && (
                                <>
                                  <i className="icon-guest text-20 mr-10"></i>
                                  Dress Code: {restaurant.dressCode}
                                </>
                              )}
                            </div>

                            <div className="d-flex items-center text-accent-1 w-auto">
                              {restaurant.restaurantOpenFor && (
                                <>
                                  {" "}
                                  <FontAwesomeIcon
                                    icon={faUtensils}
                                    className="mr-10"
                                  />
                                  Open For: {restaurant.restaurantOpenFor}
                                </>
                              )}
                            </div>
                            <div className="d-flex items-center text-accent-1 w-auto">
                              {restaurant.restaurantMenu && (
                                <>
                                  {" "}
                                  <FontAwesomeIcon
                                    icon={faClipboard}
                                    className="mr-10"
                                  />
                                  Menu: {restaurant.restaurantMenu}
                                </>
                              )}
                            </div>
                            <div className="d-flex items-center text-accent-1 w-auto">
                              {restaurant.restaurantNote && (
                                <>
                                  {" "}
                                  <FontAwesomeIcon
                                    icon={faInfo}
                                    className="mr-10"
                                  />
                                  {restaurant.restaurantNote}
                                </>
                              )}
                            </div>
                          </div>

                          <p className="mt-40 md:mt-30">
                            {restaurant.restaurantSummery}
                          </p>
                          <p className="fw-bold mt-3">
                            <FontAwesomeIcon icon={faClock} /> Opening Hours:
                          </p>
                          <div
                            className="mt-2"
                            dangerouslySetInnerHTML={{
                              __html: `${restaurant.openinghours}`,
                            }}
                          />
                          {restaurant.isMoreDetails && (
                            <button
                              className="button -md -type-2 -outline-accent-1 mt-40 md:mt-30"
                              onClick={() =>
                                navigate(
                                  `/${restaurant.hotelUrl}/dining/${restaurant.restaurantUrl}`
                                )
                              }
                            >
                              More DETAIL{" "}
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )
              )}
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default DiningType;
