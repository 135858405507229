import React, { useEffect, useRef, useState } from "react";
import Counter from "./../components/Counter";
import { Fade } from "react-awesome-reveal";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
// import { fetchHomePageData } from "../data/homeSlice";
import Loader from "../Loader";
import { Swiper, SwiperSlide } from "swiper/react";
import ModalVideo from "react-modal-video";

import "react-modal-video/css/modal-video.css";
import SwiperCore, { Navigation, Pagination } from "swiper";
import { fetchHomeGroup } from "../data/homeGroup/homeGroupSlice";
import { Helmet } from "react-helmet";
import { useMediaQuery } from "@mui/material";

SwiperCore.use([Navigation, Pagination]);
const createTiltHandler = (tiltRef) => {
  return {
    handleMove: (e) => {
      const el = tiltRef.current;
      const height = el.clientHeight;
      const width = el.clientWidth;

      const xVal = e.nativeEvent.offsetX;
      const yVal = e.nativeEvent.offsetY;

      const yRotation = 30 * ((xVal - width / 2) / width);
      const xRotation = -30 * ((yVal - height / 2) / height);

      const transformString = `perspective(1000px)  rotateX(${xRotation}deg) rotateY(${yRotation}deg) scale3d(1,1,1)`;
      el.style.transform = transformString;
    },

    handleMouseOut: () => {
      const el = tiltRef.current;
      el.style.transform =
        "perspective(1000px)   rotateX(0) rotateY(0) scale3d(1,1,1)";
    },

    handleMouseDown: () => {
      const el = tiltRef.current;
      el.style.transform =
        "perspective(1000px)   rotateX(0) rotateY(0) scale3d(1,1,1)";
    },

    handleMouseUp: () => {
      const el = tiltRef.current;
      el.style.transform =
        "perspective(1000px)   rotateX(0) rotateY(0) scale3d(1,1,1)";
    },
  };
};
const HomeGroup = () => {
  const dispatch = useDispatch();
  const { homeGroup, loading, error } = useSelector((state) => state.homeGroup);
  useEffect(() => {
    dispatch(fetchHomeGroup());
  }, [dispatch]);
  // useEffect(() => {
  //   dispatch(fetchHomePageData());
  // }, [dispatch]);
  const isMobile = useMediaQuery("(max-width:767px)");
  const isTablet = useMediaQuery("(max-width:1024px)");
  const [isOpen, setIsOpen] = useState(false);
  const tiltRef1 = useRef(null);

  const tiltHandler1 = createTiltHandler(tiltRef1);
  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };
  return (
    <>
      {loading ? (
        <Loader />
      ) : error ? (
        <section
          style={{ backgroundColor: "#293839", padding: "200px 0 100px" }}
        >
          <h1 className="text-center text-light">{error}</h1>
        </section>
      ) : (
        <>
          <Helmet>
            <title>
              {homeGroup.metatagTitle ? homeGroup.metatagTitle : "Hotels"}
            </title>
            <meta
              name="description"
              content={
                homeGroup.metatagDescription ? homeGroup.metatagDescription : ""
              }
            />
          </Helmet>
          <section className="hero -type-10 banner-mobile">
            <Swiper
              className="hero__slider js-section-slider"
              spaceBetween={0}
              slidesPerView={1}
              navigation={{
                prevEl: ".js-sliderHero-prev",
                nextEl: ".js-sliderHero-next",
              }}
              pagination={{
                clickable: true,
                el: ".js-sliderHero-pagination",
                bulletClass: "pagination__item",
                bulletActiveClass: "is-active",
                renderBullet: (index, className) => {
                  return `<div class="${className}" tabindex="0" role="button" aria-label="Go to slide ${
                    index + 1
                  }"></div>`;
                },
              }}
            >
              {homeGroup.groupSliders?.map((slide, index) => (
                <SwiperSlide key={index}>
                  <div className="hero__slide">
                    <div className="hero__bg">
                      <img
                        src={slide.sliderPhoto}
                        alt={slide.sliderMainText}
                        className="img-ratio"
                      />
                    </div>
                    <div className="hero__content text-center">
                      <div>
                        <Fade cascade direction="up">
                          <div className="hero__subtitle text-white">
                            {slide.sliderSubText}
                          </div>
                          <h1 className="hero__title capitalize text-white">
                            {slide.sliderMainText}
                          </h1>
                        </Fade>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
            <div className="hero__pagination">
              <div className="pagination -type-1 -light js-sliderHero-pagination"></div>
            </div>
            <div className="hero__nav navAbsolute">
              <button className="button text-white size-80 flex-center rounded-full js-sliderHero-prev">
                <i className="icon-arrow-left text-24"></i>
              </button>
              <button className="button text-white size-80 flex-center rounded-full js-sliderHero-next">
                <i className="icon-arrow-right text-24"></i>
              </button>
            </div>
          </section>
          <section className="layout-pt-lg layout-pb-lg">
            <div className="container">
              <div className="row y-gap-50 px-lg-5 items-center justify-between">
                <Fade cascade className="d-block col-lg-6 col-md-9">
                  <div>
                    <div className="text-15 uppercase mb-30 sm:mb-10">
                      {homeGroup.groupHomeAbout?.groupHomeAboutTitleTop}
                    </div>
                    <h2 className="text-64 md:text-40 capitalize">
                      {homeGroup.groupHomeAbout?.groupHomeAboutTitle}
                    </h2>
                    <p className="lh-18 pr-60 lg:pr-0 mt-40 md:mt-30">
                      {homeGroup.groupHomeAbout?.groupHomeAboutText}
                    </p>
                    {/*<div className="d-flex mt-50 md:mt-40">
                    <button className="button -type-1">
                      <i className="-icon">
                        <svg
                          width="50"
                          height="30"
                          viewBox="0 0 50 30"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M35.8 28.0924C43.3451 28.0924 49.4616 21.9759 49.4616 14.4308C49.4616 6.88577 43.3451 0.769287 35.8 0.769287C28.255 0.769287 22.1385 6.88577 22.1385 14.4308C22.1385 21.9759 28.255 28.0924 35.8 28.0924Z"
                            stroke="#122223"
                          ></path>
                          <path
                            d="M33.4808 10.2039L32.9985 10.8031L37.2931 14.2623H0.341553V15.0315H37.28L33.0008 18.4262L33.4785 19.0285L39 14.6492L33.4808 10.2039Z"
                            fill="#122223"
                          ></path>
                        </svg>
                      </i>
                      READ MORE
                    </button>
                  </div> */}
                  </div>
                </Fade>

                <div
                  className="col-lg-6"
                  ref={tiltRef1}
                  onMouseMove={tiltHandler1.handleMove}
                  onMouseOut={tiltHandler1.handleMouseOut}
                  onMouseDown={tiltHandler1.handleMouseDown}
                  onMouseUp={tiltHandler1.handleMouseUp}
                  style={{ transition: "none" }}
                >
                  <Fade direction="right">
                    <img
                      src={homeGroup.groupHomeAbout?.groupHomeAboutPhoto}
                      className="d-block ms-auto"
                      alt="hwaidak group"
                    />
                  </Fade>
                  {/*
          <div>
              <div className="imageGrid -type-3">

              <img
              src="intro2.jpg"
              alt=""
              
              />
              <img src="intro1.jpg" alt="" />
              </div>
              </div>
          */}
                </div>
              </div>
            </div>
          </section>
          {/*<section className="layout-pt-lg layout-pb-lg">
          <div data-anim-wrap="" className="container">
            <div className="row justify-center text-center">
              <div className="col-xl-9 col-lg-9">
                <Fade cascade direction="up">
                  <div className="text-15 uppercase mb-30 sm:mb-10">
                    {
                      homeGroup.groupHomeSecondSection
                        ?.groupHomeSecondSectionTitleTop
                    }
                  </div>
                  <h2
                    className="text-64 md:text-40 capitalize"
                    dangerouslySetInnerHTML={{
                      __html: `${homeGroup.groupHomeSecondSection?.groupHomeSecondSectionTitle}`,
                    }}
                  />
                </Fade>
              </div>
            </div>

            <div className="lineGrid -type-1 pt-100 sm:pt-50">
              <Fade direction="left">
                {" "}
                <div className="lineGrid__content">
                  <div>
                    <img
                      src={homeGroup.groupHomeSecondSection?.groupHomePhoto1}
                      alt=""
                    />
                  </div>
                </div>
              </Fade>

              <div className="lineGrid__line"></div>

              <div className="lineGrid__content -no-padding">
                <Fade direction="right">
                  <div>
                    <img
                      src={homeGroup.groupHomeSecondSection?.groupHomePhoto2}
                      alt=""
                    />
                  </div>
                </Fade>
                <div className="px-20 lg:px-0 text-center mt-60">
                  <Fade cascade direction="up">
                    <h3
                      className="text-40 sm:text-24"
                      dangerouslySetInnerHTML={{
                        __html: `${homeGroup.groupHomeSecondSection?.groupHomeSecondSectionText}`,
                      }}
                    />

                    <p
                      className="mt-20"
                      dangerouslySetInnerHTML={{
                        __html: `${homeGroup.groupHomeSecondSection?.groupHomeSecondSectionSubText}`,
                      }}
                    />
 
                  </Fade>
                </div>
              </div>
            </div>
          </div>
        </section> */}
          <section className="layout-pt-lg layout-pb-lg bg-accent-1">
            <div className="container text-center">
              <div className="row justify-center">
                <div className="col-auto">
                  <div className="text-15 uppercase text-white mb-30 sm:mb-10">
                    {homeGroup.hotels?.groupHomeHotelTitleTop}
                  </div>
                  <h2 className="text-64 md:text-40 text-white">
                    {homeGroup.hotels?.groupHomeHotelTitle}
                  </h2>
                </div>
              </div>
            </div>

            <div className="container relative">
              <div className="row justify-center pt-100 sm:pt-50">
                <div className="col-lg-10 col-md-11">
                  <Swiper
                    style={{
                      overflow: "hidden",
                      overflowX: "hidden",
                      overflowY: "visible",
                    }}
                    modules={[Navigation, Pagination]}
                    spaceBetween={50}
                    slidesPerView={1}
                    navigation={{
                      nextEl: ".js-slider1-next",
                      prevEl: ".js-slider1-prev",
                    }}
                    pagination={{
                      clickable: true,
                      el: ".js-slider1-pagination",
                      bulletClass: "pagination__item",
                      bulletActiveClass: "is-active",
                      renderBullet: (index, className) => {
                        return `<div class="${className}" tabindex="0" role="button" aria-label="Go to slide ${
                          index + 1
                        }"></div>`;
                      },
                    }}
                    loop={true}
                  >
                    {homeGroup.hotels?.hotels?.map((hotel, index) => (
                      <SwiperSlide
                        key={index}
                        style={{
                          overflow: "hidden",
                          overflowX: "hidden",
                          overflowY: "visible",
                        }}
                      >
                        <div className="roomCard -type-4">
                          <div className="ratio ratio-16:9">
                            <img
                              src={hotel.hotelPhotoGroup}
                              alt={hotel.hotelName}
                              className="img-ratio"
                            />
                          </div>
                          <div
                            className="roomCard__content bg-accent-2"
                            style={{
                              backgroundColor: "rgba(36, 44, 125, 0.5)",
                            }}
                          >
                            <Link
                              to={`/${hotel.hotelUrl}`}
                              onClick={() =>
                                localStorage.setItem("hotelUrl", hotel.hotelUrl)
                              }
                            >
                              <h3
                                className="roomCard__title lh-065 text-40"
                                style={{ color: "#fff" }}
                              >
                                {hotel.hotelName}
                              </h3>
                            </Link>
                          </div>
                        </div>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
              </div>
              <div className="row justify-center pt-100 sm:pt-50">
                <div className="col-xl-10">
                  <div className="row justify-between items-center">
                    <div className="col-auto">
                      <button className="button -outline-white text-white size-80 md:size-60 flex-center rounded-full js-slider1-prev">
                        <i className="icon-arrow-left text-24 md:text-19"></i>
                      </button>
                    </div>
                    <div className="col-auto">
                      <div className="pagination -type-1 -light js-slider1-pagination"></div>
                    </div>
                    <div className="col-auto">
                      <button className="button -outline-white text-white size-80 md:size-60 flex-center rounded-full js-slider1-next">
                        <i className="icon-arrow-right text-24 md:text-19"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="layout-pt-md layout-pb-md second-section-position">
            <div className="container">
              <div className="row justify-center text-center">
                <div className="col-xl-8 col-lg-10">
                  <Fade cascade direction="up">
                    <div>
                      <div className="text-15 uppercase mb-30 sm:mb-10">
                        {homeGroup.groupHomeIntro?.groupHomeIntroTitleTop}
                      </div>

                      <h2 className="text-64 md:text-40 capitalize">
                        {homeGroup.groupHomeIntro?.groupHomeIntroTitle}
                      </h2>
                    </div>

                    <div className="row justify-center">
                      <div className="col-lg-8">
                        <p className="mt-40 md:mt-20">
                          {homeGroup.groupHomeIntro?.groupHomeIntroText}
                        </p>
                      </div>
                    </div>

                    {/* <div>
                    <button className="button -type-1 mx-auto mt-50 md:mt-30">
                      <i className="-icon">
                        <svg
                          width="50"
                          height="30"
                          viewBox="0 0 50 30"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M35.8 28.0924C43.3451 28.0924 49.4616 21.9759 49.4616 14.4308C49.4616 6.88577 43.3451 0.769287 35.8 0.769287C28.255 0.769287 22.1385 6.88577 22.1385 14.4308C22.1385 21.9759 28.255 28.0924 35.8 28.0924Z"
                            stroke="#122223"
                          ></path>
                          <path
                            d="M33.4808 10.2039L32.9985 10.8031L37.2931 14.2623H0.341553V15.0315H37.28L33.0008 18.4262L33.4785 19.0285L39 14.6492L33.4808 10.2039Z"
                            fill="#122223"
                          ></path>
                        </svg>
                      </i>
                      READ MORE
                    </button>
                  </div> */}
                  </Fade>
                </div>
              </div>

              <div className="row justify-center text-center pt-100 lg:pt-50">
                <div className="col-xl-10 col-lg-11">
                  <Fade cascade>
                    <div className="row y-gap-30 justify-content-center justify-content-lg-between">
                      {homeGroup.groupHomeIntro?.groupHomeIntroActivities?.map(
                        (item, index) => (
                          <div className="col-auto" key={index}>
                            <h3 className="text-92 lg:text-60 md:text-30 lh-065">
                              <Counter
                                start={0}
                                end={item.groupHomeIntroActivitiesNumber}
                                step={1}
                              />
                              {item.groupHomeIntroActivitiesQuantity}
                            </h3>
                            <div className="uppercase lh-1 mt-30">
                              {item.groupHomeIntroActivitiesText}
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  </Fade>
                </div>
              </div>
            </div>
          </section>
          {/* <section className="relative layout-pt-lg layout-pb-lg">
            <div className="sectionBg">
              <img
                src={
                  isMobile
                    ? homeGroup.groupHomeVideo
                        ?.groupHomeVideoSectionBannerMobile
                    : isTablet
                    ? homeGroup.groupHomeVideo
                        ?.groupHomeVideoSectionBannerTablet
                    : homeGroup.groupHomeVideo?.groupHomeVideoSectionBanner
                }
                alt=""
                className="img-ratio"
              />
            </div>
            <div className="container">
              <div className="row justify-center text-center">
                <div className="col-12">
                  <div data-split="lines">
                    <Fade cascade direction="up">
                      <div className="text-15 uppercase text-white mb-30 sm:mb-10">
                        {
                          homeGroup.groupHomeVideo
                            ?.groupHomeVideoSectionTitleTop
                        }
                      </div>
                      <h2
                        className="col-lg-8 mx-auto text-center text-92 lh:text-72 md:text-40 capitalize text-white"
                        dangerouslySetInnerHTML={{
                          __html: `${homeGroup.groupHomeVideo?.groupHomeVideoSectionTitle}`,
                        }}
                      />
                    </Fade>
                  </div>

                  <div className="d-flex flex-column items-center mt-40">
                    <Fade direction="up">
                      <div
                        className="size-80 flex-center rounded-full bg-accent-1-50 blur-1 border-white-10"
                        onClick={handleOpen}
                      >
                        <i className="icon-play text-21 fw-500 text-white"></i>
                      </div>
                      <button
                        className="text-13 text-white mt-20 vid"
                        onClick={handleOpen}
                      >
                        PLAY
                      </button>
                    </Fade>
                    <ModalVideo
                      channel="youtube"
                      isOpen={isOpen}
                      videoId={homeGroup.groupHomeVideo?.groupHomeVideoUrl}
                      onClose={handleClose}
                    />
                  </div>
                </div>
              </div>
            </div>{" "}
          </section> */}
          {/*
          <section className="layout-pt-lg layout-pb-lg">
            <div data-anim-wrap="" className="container animated">
              <div className="row y-gap-30 justify-between items-end">
                <div className="col-auto">
                  <Fade cascade direction="left">
                    {" "}
                    <div className="text-15 uppercase mb-30 sm:mb-10">
                      {homeGroup.hotelNews?.groupHomeNewsTitleTop}
                    </div>
                    <h2 className="text-64 md:text-40">
                      {homeGroup.hotelNews?.groupHomeNewsTitle}{" "}
                    </h2>
                  </Fade>
                </div>

                <div className="col-auto is-in-view">
                  <Fade direction="right">
                    <button className="button -type-1">
                      <i className="-icon">
                        <svg
                          width="50"
                          height="30"
                          viewBox="0 0 50 30"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M35.8 28.0924C43.3451 28.0924 49.4616 21.9759 49.4616 14.4308C49.4616 6.88577 43.3451 0.769287 35.8 0.769287C28.255 0.769287 22.1385 6.88577 22.1385 14.4308C22.1385 21.9759 28.255 28.0924 35.8 28.0924Z"
                            stroke="#122223"
                          ></path>
                          <path
                            d="M33.4808 10.2039L32.9985 10.8031L37.2931 14.2623H0.341553V15.0315H37.28L33.0008 18.4262L33.4785 19.0285L39 14.6492L33.4808 10.2039Z"
                            fill="#122223"
                          ></path>
                        </svg>
                      </i>
                      Read ALL MORE
                    </button>
                  </Fade>
                </div>
              </div>

              <div className="row y-gap-30 x-gap-85 justify-between pt-100 sm:pt-50">
                <div className="col-lg-4 col-md-6">
                  <a href="#." className="baseCard -type-2">
                    <div className="baseCard__image ratio ratio-41:50 is-in-view">
                      <img
                        src="img/cards/2/1.png"
                        alt=""
                        className="img-ratio"
                      />
                    </div>

                    <div className="baseCard__content mt-30 is-in-view">
                      <div className="row x-gap-10">
                        <div className="col-auto">06 March 2024</div>
                      </div>

                      <h4 className="text-30 md:text-25 mt-15">
                        orem Ipsum is simply dummy text of the printing and
                        typesetting industry
                      </h4>

                      <div className="d-flex mt-20">
                        <button className="button -type-1">
                          <i className="-icon">
                            <svg
                              width="50"
                              height="30"
                              viewBox="0 0 50 30"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M35.8 28.0924C43.3451 28.0924 49.4616 21.9759 49.4616 14.4308C49.4616 6.88577 43.3451 0.769287 35.8 0.769287C28.255 0.769287 22.1385 6.88577 22.1385 14.4308C22.1385 21.9759 28.255 28.0924 35.8 28.0924Z"
                                stroke="#122223"
                              ></path>
                              <path
                                d="M33.4808 10.2039L32.9985 10.8031L37.2931 14.2623H0.341553V15.0315H37.28L33.0008 18.4262L33.4785 19.0285L39 14.6492L33.4808 10.2039Z"
                                fill="#122223"
                              ></path>
                            </svg>
                          </i>
                          READ MORE
                        </button>
                      </div>
                    </div>
                  </a>
                </div>

                <div className="col-lg-4 col-md-6">
                  <a href="#." className="baseCard -type-2">
                    <div className="baseCard__image ratio ratio-41:50 is-in-view">
                      <img src="news2.jpg" alt=" " className="img-ratio" />
                    </div>

                    <div className="baseCard__content mt-30 is-in-view">
                      <div className="row x-gap-10">
                        <div className="col-auto">06 March 2024</div>
                      </div>

                      <h4 className="text-30 md:text-25 mt-15">
                        orem Ipsum is simply dummy text of the printing and
                        typesetting industry
                      </h4>

                      <div className="d-flex mt-20">
                        <button className="button -type-1">
                          <i className="-icon">
                            <svg
                              width="50"
                              height="30"
                              viewBox="0 0 50 30"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M35.8 28.0924C43.3451 28.0924 49.4616 21.9759 49.4616 14.4308C49.4616 6.88577 43.3451 0.769287 35.8 0.769287C28.255 0.769287 22.1385 6.88577 22.1385 14.4308C22.1385 21.9759 28.255 28.0924 35.8 28.0924Z"
                                stroke="#122223"
                              ></path>
                              <path
                                d="M33.4808 10.2039L32.9985 10.8031L37.2931 14.2623H0.341553V15.0315H37.28L33.0008 18.4262L33.4785 19.0285L39 14.6492L33.4808 10.2039Z"
                                fill="#122223"
                              ></path>
                            </svg>
                          </i>
                          READ MORE
                        </button>
                      </div>
                    </div>
                  </a>
                </div>
                <div className="col-lg-4 col-md-6">
                  <a href="#." className="baseCard -type-2">
                    <div className="baseCard__image ratio ratio-41:50 is-in-view">
                      <img src="news3.jpg" alt=" " className="img-ratio" />
                    </div>

                    <div className="baseCard__content mt-30 is-in-view">
                      <div className="row x-gap-10">
                        <div className="col-auto">06 March 2024</div>
                      </div>

                      <h4 className="text-30 md:text-25 mt-15">
                        orem Ipsum is simply dummy text of the printing and
                        typesetting industry
                      </h4>

                      <div className="d-flex mt-20">
                        <button className="button -type-1">
                          <i className="-icon">
                            <svg
                              width="50"
                              height="30"
                              viewBox="0 0 50 30"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M35.8 28.0924C43.3451 28.0924 49.4616 21.9759 49.4616 14.4308C49.4616 6.88577 43.3451 0.769287 35.8 0.769287C28.255 0.769287 22.1385 6.88577 22.1385 14.4308C22.1385 21.9759 28.255 28.0924 35.8 28.0924Z"
                                stroke="#122223"
                              ></path>
                              <path
                                d="M33.4808 10.2039L32.9985 10.8031L37.2931 14.2623H0.341553V15.0315H37.28L33.0008 18.4262L33.4785 19.0285L39 14.6492L33.4808 10.2039Z"
                                fill="#122223"
                              ></path>
                            </svg>
                          </i>
                          READ MORE
                        </button>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
                      </section> */}
        </>
      )}
    </>
  );
};

export default HomeGroup;
